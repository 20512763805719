:root {
	--pw-primary-lightest-t20: #33ffd833;
	--pw-primary-lightest-t30: #33ffd84D;
	--pw-primary-lightest: #33ffd8;
	--pw-primary-lighter: #2ee8c5;
	--pw-primary-light: #28d1b4;
	--pw-primary-t10: #21b9a51a;
	--pw-primary-t20: #21b9a533;
	--pw-primary-t30: #21b9a54d;
	--pw-primary-t35: #21b9a559;
	--pw-primary-t40: #21b9a566;
	--pw-primary: #21b9a5;
	--pw-primary-dark: #1e9e88;
    --pw-primary-darker: #198472;
    --pw-primary-darkest: #156858;
    --pw-primary-darkest-transparent: #19847281;
	--pw-primary-35: #b2e7e0;

	--pw-green-lightest-t20: #72FF6433;
	--pw-green-lightest-t30: #72FF644D;
	--pw-green-lightest: #72FF64;
	--pw-green-light: #14ce00;
	--pw-green-t10: #0ebf001a;
	--pw-green-t20: #0ebf0033;
	--pw-green-t30: #0ebf004d;
	--pw-green-t35: #0ebf0059;
	--pw-green-t40: #0ebf0066;
	--pw-green: #0ebf00;
	--pw-green-dark: #0f9e00;
	--pw-green-darker: #118900;
	--pw-green-darkest: #0c6b00;
	--pw-green-35: #abe9a6;

	--pw-magenta-lightest-t20: #FF8CE133;
	--pw-magenta-lightest-t30: #FF8CE14D;
	--pw-magenta-lightest: #FF8CE1;
	--pw-magenta-light: #f33d98;
	--pw-magenta-t10: #d1287d1a;
	--pw-magenta-t20: #d1287d33;
	--pw-magenta-t30: #d1287d4d;
	--pw-magenta-t35: #d1287d59;
	--pw-magenta-t40: #d1287d66;
	--pw-magenta: #d1287d;
	--pw-magenta-dark: #c01f6f;
	--pw-magenta-darker: #9e0e56;
	--pw-magenta-darkest: #740b3f;

	--pw-orange-lightest-t20: #FFD09733;
	--pw-orange-lightest-t30: #FFD0974D;
	--pw-orange-lightest: #FFD097;
	--pw-orange-light: #fc835e;
	--pw-orange-t10: #f36c331a;
	--pw-orange-t20: #f36c3333;
	--pw-orange-t30: #f36c334d;
	--pw-orange-t35: #f36c3359;
	--pw-orange-t40: #f36c3366;
	--pw-orange: #f36c33;
	--pw-orange-dark: #E75D22;
	--pw-orange-darker: #cf4a11;
	--pw-orange-darkest: #91330b;
	--pw-orange-35: #fbccb8;

	--pw-blue-lightest-t20:#80E0FF33;
	--pw-blue-lightest-t30:#80E0FF4D;
	--pw-blue-lightest:#80E0FF;
	--pw-blue-light:#398CF8;
	--pw-blue-t10: #1c7cfa1a;
	--pw-blue-t20: #1c7cfa33;
	--pw-blue-t30: #1c7cfa4d;
	--pw-blue-t35: #1c7cfa59;
	--pw-blue-t40: #1c7cfa66;
	--pw-blue: #1c7cfa;
	--pw-blue-dark: #0957bd;
	--pw-blue-darker: #03469e;
	--pw-blue-darkest: #04244e;
	--pw-blue-35: #b0d1fd;

	--pw-violet-lightest-t20:#CFA3FF33;
	--pw-violet-lightest-t30:#CFA3FF4D;
	--pw-violet-lightest:#CFA3FF;
	--pw-violet-light:#5228d1;
	--pw-violet-t10: #4d21b91a;
	--pw-violet-t20: #4d21b933;
	--pw-violet-t30: #4d21b94d;
	--pw-violet-t35: #4d21b959;
	--pw-violet-t40: #4d21b966;
	--pw-violet: #4d21b9;
	--pw-violet-dark: #3e1e9e;
	--pw-violet-darker: #341984;
	--pw-violet-darkest: #221057;
	--pw-violet-35: #c1b2e7;

	--pw-yellow-lightest-t20:#FFFF9D33;
	--pw-yellow-lightest-t30:#FFFF9D4D;
	--pw-yellow-lightest:#FFFF9D;
	--pw-yellow-light:#FFD374;
	--pw-yellow-t02:#F8BC3905;
	--pw-yellow-t05:#F8BC390D;
	--pw-yellow-t10:#F8BC391a;
	--pw-yellow-t20:#F8BC3933;
	--pw-yellow-t30:#F8BC394d;
	--pw-yellow-t35:#F8BC3959;
	--pw-yellow-t40:#F8BC3966;
	--pw-yellow:#F8BC39;
	--pw-yellow-dark: #F0A10A;
	--pw-yellow-darker: #D98D00;
	--pw-yellow-darkest: #A86200;
	--pw-yellow-35:#fde8ba;

	--pw-pink-lightest-t20:#ffe0fa33;
	--pw-pink-lightest-t30:#ffe0fa4D;
	--pw-pink-lightest:#ffe0fa;
	--pw-pink-light:#ffc7f5;
	--pw-pink-t02:#ffadf105;
	--pw-pink-t05:#ffadf10D;
	--pw-pink-t10:#ffadf11a;
	--pw-pink-t20:#ffadf133;
	--pw-pink-t30:#ffadf14d;
	--pw-pink-t35:#ffadf159;
	--pw-pink-t40:#ffadf166;
	--pw-pink:#ffadf1;
	--pw-pink-dark: #e59bd8;
	--pw-pink-darker: #cc8ac0;
	--pw-pink-darkest: #b279a8;
	--pw-pink-35:#FFCDF6;

	/* https://hexcolorcodes.org/monochromatic-colors-generator */

	--pw-male:#1c7cfa;
	--pw-female:#d1287d;
	--pw-male-hover:#0957bd;
	--pw-female-hover:#c01f6f;

	--pw-red: #EC3F5A;
	--pw-pink: #ff5cde;
	--pw-gold: #9A822C;
	--pw-silver: #919191;
	--pw-black: #000;
	--pw-black-9: rgba(0, 0, 0, 0.9);
	--pw-black-8: rgba(0, 0, 0, 0.8);
	--pw-black-7: rgba(0, 0, 0, 0.7);
	--pw-black-6: rgba(0, 0, 0, 0.6);
	--pw-black-5: rgba(0, 0, 0, 0.5);
	--pw-black-4: rgba(0, 0, 0, 0.4);
	--pw-black-3: rgba(0, 0, 0, 0.3);
	--pw-black-2: rgba(0, 0, 0, 0.2);
	--pw-black-1: rgba(0, 0, 0, 0.1);
	--pw-white-9: rgba(255, 255, 255, 0.9);
	--pw-white-8: rgba(255, 255, 255, 0.8);
	--pw-white-7: rgba(255, 255, 255, 0.7);
	--pw-white-6: rgba(255, 255, 255, 0.6);
	--pw-white-5: rgba(255, 255, 255, 0.5);
	--pw-white-4: rgba(255, 255, 255, 0.4);
	--pw-white-3: rgba(255, 255, 255, 0.3);
	--pw-white-2: rgba(255, 255, 255, 0.2);
	--pw-white-1: rgba(255, 255, 255, 0.1);
	--pw-gray-darkestextra: #030303;
	--pw-gray-darkest: #1d1d1d;
	--pw-gray-darker: #252526;
	--pw-gray-dark: #595959;
	--pw-gray-lightest-t20:#95959533;
	--pw-gray-lightest-t30:#9595954D;
	--pw-gray-t10: #9595951a;
	--pw-gray-t20: #95959533;
	--pw-gray-t30: #9595954d;
	--pw-gray: #959595;
	--pw-gray-empty: #CBCBCB;
	--pw-gray-light: #EBEDF2;
	--pw-gray-post: #F5F6F8;
	--pw-white: #FFF;
}

.nav-sidebar .nav-item > .nav-link {
    margin-bottom: 0;
	padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: black !important;
}

.sidebar {
    padding-left: 0;
    padding-right: 0;
}

.nav-link{
	font-size: 1.1em;
}

.nav-item.has-treeview{
	width: 100%;
}

.nav-item{
	padding-top: .4rem;
	padding-bottom: .4rem;
	color: var(--pw-gray-darker);
}

.nav-item:hover{
	color: var(--pw-gray-darkestextra);
}

.nav-item ul{
	margin-right: -.1rem !important;
	margin-bottom: -.1rem !important;
}

.nav-sidebar .nav-item{
	//margin-left: 5px;
	margin-top: .1rem;
	//margin-bottom: .1rem;
	//border-radius: 5px;
	//width: 100%;
}

.box-shadow
{
	border-radius: 10px !important;
	-webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
	box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0)
}

.pw-primary.nav-item
{
	border: 2px solid var(--pw-primary-35);
	margin-top: 2px;
	margin-bottom: 2px;
}

.pw-primary.nav-item ul{
	background: var(--pw-primary-35) !important;
}

.pw-green.nav-item
{
	border: 2px solid var(--pw-green-35);
	margin-top: 2px;
	margin-bottom: 2px;
}

.pw-green.nav-item a.nav-link,
.pw-green.nav-item a.nav-link i{
	color: var(--pw-gray-darkestextra) !important;
}

.pw-blue.nav-item
{
	border: 2px solid var(--pw-blue-35);
	margin-top: 2px;
	margin-bottom: 2px;
}

.pw-blue.nav-item ul{
	background: var(--pw-blue-35) !important;
}

.pw-blue.nav-item a.nav-link,
.pw-blue.nav-item a.nav-link i{
	color: var(--pw-gray-darkestextra) !important;
}

.pw-violet.nav-item
{
	border: 2px solid var(--pw-violet-35);
	margin-top: 2px;
	margin-bottom: 2px;
}

.pw-violet.nav-item ul{
	background: var(--pw-violet-35) !important;
}

.pw-violet.nav-item a.nav-link,
.pw-violet.nav-item a.nav-link i{
	color: var(--pw-gray-darkestextra) !important;
}

.pw-orange.nav-item{
	border: 2px solid var(--pw-orange-35);
	margin-top: 2px;
	margin-bottom: 2px;
}

.pw-orange.nav-item a.nav-link,
.pw-orange.nav-item a.nav-link i{
	color: var(--pw-gray-darkestextra) !important;
}

.pw-orange a:hover,
.pw-orange .btn-link:hover {
    color: var(--pw-orange-dark);
	text-decoration: none;
	font-weight: 700;
}

.pw-yellow.nav-item
{
	border: 2px solid var(--pw-yellow-35);
	margin-top: 2px;
	margin-bottom: 2px;
}

.pw-yellow.nav-item a.nav-link,
.pw-yellow.nav-item a.nav-link i{
	color: var(--pw-gray-darkestextra) !important;
}

.pw-pink.nav-item
{
	border: 2px solid var(--pw-pink-35);
	margin-top: 2px;
	margin-bottom: 2px;
}

.pw-pink.nav-item a.nav-link,
.pw-pink.nav-item a.nav-link i{
	color: var(--pw-gray-darkestextra) !important;
}

.nav-sidebar > .nav-item .nav-icon {
    font-size: 1.8rem;
	line-height: normal;
}

.nav-sidebar.nav-child-indent .nav-treeview {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.nav-sidebar .nav-link > .right, .nav-sidebar .nav-link > p > .right {
    top: 1.2rem;
	display: none;
}

.menu-icon-desktop{
	/*
	width: 60px;
	height: 60px;
	width: 3.125vw;
	height: 3.125vw;
	*/
	/*
	width: 50px;
	height: 50px;
	*/
	width: 2.604vw;
	height: 2.604vw;
}

.nav-link {
    font-size: 1em;
}

#app > aside > a{
	border-bottom: none;
}

.nav-sidebar .nav-link > p > .right:nth-child(2) {
    right: 0.4rem;
}

.mobile .mobile-content-row{
	padding-top: 50px;
	padding-bottom: 50px;
}

.mobile .mobile-content-row > div{
	width: 100%;
}

.img-avatar {
	vertical-align: middle;
	width: 70px;
}

/*Fill per SVG*/

.fill-pw-primary-light {
	fill:var(--pw-primary-light);
}

.fill-pw-primary {
	fill:var(--pw-primary);
}

.fill-pw-primary-dark {
	fill:var(--pw-primary-dark);
}

.fill-pw-primary-darker {
	fill:var(--pw-primary-darker);
}

.fill-pw-primary-darkest {
	fill:var(--pw-primary-darkest);
}

.fill-pw-orange-light {
	fill:var(--pw-orange-light);
}

.fill-pw-orange {
	fill:var(--pw-orange);
}

.fill-pw-orange-dark {
	fill:var(--pw-orange-dark);
}

.fill-pw-orange-darker {
	fill:var(--pw-orange-darker);
}

.fill-pw-orange-darkest {
	fill:var(--pw-orange-darkest);
}

.fill-pw-magenta-light {
	fill:var(--pw-magenta-light);
}

.fill-pw-magenta {
	fill:var(--pw-magenta);
}

.fill-pw-magenta-dark {
	fill:var(--pw-magenta-dark);
}

.fill-pw-magenta-darker {
	fill:var(--pw-magenta-darker);
}

.fill-pw-magenta-darkest {
	fill:var(--pw-magenta-darkest);
}

.fill-pw-blue-light {
	fill:var(--pw-blue-light);
}

.fill-pw-blue {
	fill:var(--pw-blue);
}

.fill-pw-blue-dark {
	fill:var(--pw-blue-dark);
}

.fill-pw-blue-darker {
	fill:var(--pw-blue-darker);
}

.fill-pw-blue-darkest {
	fill:var(--pw-blue-darkest);
}

.fill-pw-primary-darkest {
	fill:var(--pw-primary-darkest);
}

.fill-pw-white {
	fill:var(--pw-white);
}

.fill-pw-gray-dark {
	fill:var(--pw-gray-darker);
}

.fill-none {
	fill:none;
}

.svg-box-stroke {
	stroke:var(--pw-gray-darker);
}

.svg-box-fill,
.svg-box-path {
	fill:var(--pw-gray-darker);
}

.svg-box-font {
	font-size: 12px; font-family: MyriadPro-Regular, Myriad Pro;
}

/*Text Color*/

.inherit {
	color: inherit !important;
}

.pw-primary {
	color:var(--pw-primary);
}

.pw-primary-light {
	color:var(--pw-primary-light);
}

.pw-primary-lighter {
	color:var(--pw-primary-lighter);
}

.pw-primary-lightest {
	color:var(--pw-primary-lightest);
}

.pw-primary-dark {
	color:var(--pw-primary-dark);
}

.pw-primary-darker {
	color:var(--pw-primary-darker);
}

.pw-primary-darkest {
	color:var(--pw-primary-darkest);
}

.pw-primary.nav-item a.nav-link,
.pw-primary.nav-item a.nav-link i{
	color: var(--pw-gray-darkestextra) !important;
}
// ----------------------------------------------

// ---- GREEN - INIZIO
body.pw-green{
	background-color: var(--pw-white);
	background-image: none;
}

.pw-green .content-wrapper,
.pw-green .mobile-content-row{
	background-color: var(--pw-white);
	/*background-color: var(--pw-green-lightest-t20);*/
}

.pw-green.pw-switch .content-wrapper,
.pw-green.pw-switch .mobile-content-row{
	background-color: var(--pw-green-lightest-t20);
	background-image: url('/img/background/wave-full-background-hd-t50.png');
}

.pw-green .white-bg{
	background-color: transparent;
	border: none;
}

.pw-green .page-helper {
    border: 2px dashed var(--pw-green) !important;
	background-color: var(--pw-green-t30);
	color: var(--pw-gray-darkest);
}

.pw-green .container-fluid .page-title,
.pw-green .container-fluid .page-title-number,
.pw-green .container-fluid .register_page label{
	color:var(--pw-green);
}

.pw-green .container-fluid a{
	color:var(--pw-green-dark);
}

.pw-green .card{
	// border-color:var(--pw-green) !important;
	border-color: transparent !important;
}

.pw-green .card .category{
	background-color: var(--pw-green);
}

.pw-green a:hover,
.pw-green .btn-link:hover {
    color: var(--pw-green-dark);
	text-decoration: none;
	font-weight: 700;
}

.pw-green-nav-back{
	background: var(--pw-green-light) !important;
}

.pw-green .btn-success,
.pw-green .btn.btn-petwave,
.pw-green #mapHelper{
	background-color: var(--pw-green-t30);
	border-color: var(--pw-green);
	color: var(--pw-gray-darkest);
	border-radius: 0.5rem;
}

.pw-green .btn.btn-petwave:not(:disabled):not(.disabled):active,
.pw-green .btn.btn-petwave:not(:disabled):not(.disabled).active,
.pw-green .show > .btn-petwave.dropdown-toggle{
	background-color: var(--pw-green-dark);
}

.pw-green .btn-success:hover,
.pw-green .btn-success:active,
.pw-green .btn.btn-petwave:hover,
.pw-green .btn.btn-petwave.active,
.pw-green .btn.btn-outline-petwave:hover,
.pw-green .btn.btn-outline-petwave.active{
	background-color: var(--pw-green-darker);
    border-color: var(--pw-green-darker);
	color: var(--pw-white);
}

.pw-green .white-bg textarea:focus,
.pw-green .white-bg select:focus,
.pw-green .white-bg input:focus,
.pw-green .white-bg .select2-container--open .selection > span,
.pw-green .select2-container--default .select2-dropdown,
.pw-green .select2-dropdown input span,
.pw-green .modal-content textarea:focus,
.pw-green .modal-content input:focus,
.pw-green .custom-file-label,
.pw-green .tab-content select:focus,
.pw-green .tab-content input:focus{
	box-shadow: 0 0 0 0.05rem var(--pw-green);
}

.pw-green .container-fluid h1,
.pw-green .container-fluid p,
.pw-green .container-fluid a,
.pw-green .container-fluid .page-title,
.pw-green .container-fluid .page-title-number,
.pw-green .container-fluid .card,
.pw-green .container-fluid .register_page label{
	color:var(--pw-green-darker);
}
// ---- GREEN - FINE

// ---- MAGENTA - INIZIO
body.pw-magenta{
	background-color: var(--pw-white);
	background-image: none;
}

.pw-magenta .content-wrapper,
.pw-magenta .mobile-content-row{
	background-color: var(--pw-white);
	/*background-color: var(--pw-magenta-lightest-t20);*/
}

.pw-magenta .white-bg{
	background-color: transparent;
	border: none;
}

.pw-magenta .page-helper {
    border: 2px dashed var(--pw-magenta) !important;
	background-color: var(--pw-magenta-t30);
	color: var(--pw-gray-darkest);
}

.pw-magenta .container-fluid .page-title,
.pw-magenta .container-fluid .page-title-number,
.pw-magenta .container-fluid .register_page label{
	color:var(--pw-magenta);
}

.pw-magenta .container-fluid a{
	color:var(--pw-magenta-dark);
}

.pw-magenta .card{
	border-color:var(--pw-magenta) !important;
}

.pw-magenta .card .category{
	background-color: var(--pw-magenta);
}

.pw-magenta a:hover,
.pw-magenta .btn-link:hover {
    color: var(--pw-magenta-dark);
	text-decoration: none;
	font-weight: 700;
}

.pw-magenta-nav-back{
	background: var(--pw-magenta-light) !important;
}

.pw-magenta .btn-success,
.pw-magenta .btn.btn-petwave,
.pw-magenta #mapHelper{
	color: var(--pw-white);
	background-color: var(--pw-magenta);
	border-color: var(--pw-magenta);
	border-radius: 0.5rem;
}

.pw-magenta .btn.btn-petwave:not(:disabled):not(.disabled):active,
.pw-magenta .btn.btn-petwave:not(:disabled):not(.disabled).active,
.pw-magenta .show > .btn-petwave.dropdown-toggle{
	background-color: var(--pw-magenta-dark);
}

.pw-magenta .btn-success:hover,
.pw-magenta .btn-success:active,
.pw-magenta .btn.btn-petwave:hover,
.pw-magenta .btn.btn-petwave.active,
.pw-magenta .btn.btn-outline-petwave:hover,
.pw-magenta .btn.btn-outline-petwave.active{
	background-color: var(--pw-magenta-darker);
    border-color: var(--pw-magenta-darker);
}

.pw-magenta .white-bg textarea:focus,
.pw-magenta .white-bg select:focus,
.pw-magenta .white-bg input:focus,
.pw-magenta .white-bg .select2-container--open .selection > span,
.pw-magenta .select2-container--default .select2-dropdown,
.pw-magenta .select2-dropdown input span,
.pw-magenta .modal-content textarea:focus,
.pw-magenta .modal-content input:focus,
.pw-magenta .custom-file-label,
.pw-magenta .tab-content select:focus,
.pw-magenta .tab-content input:focus{
	box-shadow: 0 0 0 0.05rem var(--pw-magenta);
}
// ---- MAGENTA - FINE

// ---- ORANGE - INIZIO
body.pw-orange{
	background-color: var(--pw-white);
	background-image: none;
}

.pw-orange .content-wrapper,
.pw-orange .mobile-content-row{
	background-color: var(--pw-white);
	/*background-color: var(--pw-orange-lightest-t20);*/
}

.pw-orange.pw-switch .content-wrapper,
.pw-orange.pw-switch .mobile-content-row{
	background-color: var(--pw-orange-lightest-t20);
	background-image: url('/img/background/wave-full-background-hd-t50.png');
}

.pw-orange .white-bg{
	background-color: transparent;
	border: none;
}

.pw-orange .page-helper {
    border: 2px dashed var(--pw-orange) !important;
	background-color: var(--pw-orange-t30);
	color: var(--pw-gray-darkest);
}

.pw-orange .container-fluid .page-title,
.pw-orange .container-fluid .page-title-number,
.pw-orange .container-fluid .register_page label{
	color: var(--pw-orange-darkest);
}

.pw-orange .container-fluid a{
	color:var(--pw-orange-dark);
}

.pw-orange .card{
	border-color:var(--pw-orange) !important;
}

.pw-orange .card .category{
	background-color: var(--pw-orange);
}

.pw-orange-nav-back{
	background: var(--pw-orange-light) !important;
}

.pw-orange .btn-success,
.pw-orange .btn.btn-petwave,
.pw-orange #mapHelper{
	color: var(--pw-white) !important;
	background-color: var(--pw-orange);
	border-color: var(--pw-orange);
	border-radius: 0.5rem;
}

.pw-orange .btn.btn-petwave:not(:disabled):not(.disabled):active,
.pw-orange .btn.btn-petwave:not(:disabled):not(.disabled).active,
.pw-orange .show > .btn-petwave.dropdown-toggle{
	background-color: var(--pw-orange-dark);
}

.pw-orange .btn-success:hover,
.pw-orange .btn-success:active,
.pw-orange .btn.btn-petwave:hover,
.pw-orange .btn.btn-petwave.active,
.pw-orange .btn.btn-outline-petwave:hover,
.pw-orange .btn.btn-outline-petwave.active{
	background-color: var(--pw-orange-darker);
    border-color: var(--pw-orange-darker);
}

.pw-orange .white-bg textarea:focus,
.pw-orange .white-bg select:focus,
.pw-orange .white-bg input:focus,
.pw-orange .white-bg .select2-container--open .selection > span,
.pw-orange .select2-container--default .select2-dropdown,
.pw-orange .select2-dropdown input span,
.pw-orange .modal-content textarea:focus,
.pw-orange .modal-content input:focus,
.pw-orange .custom-file-label,
.pw-orange .tab-content select:focus,
.pw-orange .tab-content input:focus{
	box-shadow: 0 0 0 0.05rem var(--pw-orange);
}

.pw-orange .container-fluid h1,
.pw-orange .container-fluid p,
.pw-orange .container-fluid a,
.pw-orange .container-fluid .page-title,
.pw-orange .container-fluid .page-title-number,
.pw-orange .container-fluid .card,
.pw-orange .container-fluid .register_page label{
	color:var(--pw-orange-darker);
}

// ---- ORANGE - FINE

// ---- VIOLET - INIZIO
body.pw-violet{
	background-color: var(--pw-white);
	background-image: none;
}

.pw-violet .content-wrapper,
.pw-violet .mobile-content-row{
	background-color: var(--pw-white);
	/*background-color: var(--pw-violet-lightest-t20);*/
}

.pw-violet.pw-switch .content-wrapper,
.pw-violet.pw-switch .mobile-content-row{
	background-color: var(--pw-violet-lightest-t20);
	background-image: url('/img/background/wave-full-background-hd-t50.png');
}

.pw-violet .white-bg{
	background-color: transparent;
	border: none;
}

.pw-violet .page-helper {
    border: 2px dashed var(--pw-violet) !important;
	background-color: var(--pw-violet-t-30);
	color: var(--pw-gray-darkest);
}

.pw-violet .container-fluid h1,
.pw-violet .container-fluid p,
.pw-violet .container-fluid a,
.pw-violet .container-fluid .page-title,
.pw-violet .container-fluid .page-title-number,
.pw-violet .container-fluid .card,
.pw-violet .container-fluid .register_page label{
	color:var(--pw-violet);
}

.pw-violet .card{
	border-color: transparent !important;
}

.pw-violet .card-header{
	background: transparent;
	background-color: transparent;
	color: var(--pw-violet-dark);
	border-bottom: none;
}

.pw-violet .card-title {
    float: inherit;
}

.card-footer {
    background: transparent;
	background-color: transparent;
    border-top: none;
}

.pw-violet a:hover,
.pw-violet .btn-link:hover {
    color: var(--pw-violet-dark);
	text-decoration: none;
	font-weight: 700;
}

.pw-violet-nav-back{
	background: var(--pw-violet-lightest) !important;
}

.pw-violet .btn-success,
.pw-violet .btn.btn-petwave,
.pw-violet #mapHelper{
	color: var(--pw-white);
	background-color: var(--pw-violet);
	border-color: var(--pw-violet);
	border-radius: 0.5rem;
}

.pw-violet .btn.btn-petwave:not(:disabled):not(.disabled):active,
.pw-violet .btn.btn-petwave:not(:disabled):not(.disabled).active,
.pw-violet .show > .btn-petwave.dropdown-toggle{
	background-color: var(--pw-violet-dark);
}
.pw-violet .btn-success:hover,
.pw-violet .btn-success:active,
.pw-violet .btn.btn-petwave:hover,
.pw-violet .btn.btn-petwave.active,
.pw-violet .btn.btn-outline-petwave:hover,
.pw-violet .btn.btn-outline-petwave.active{
	background-color: var(--pw-violet-darker);
    border-color: var(--pw-violet-darker);
}

.pw-violet .white-bg textarea:focus,
.pw-violet .white-bg select:focus,
.pw-violet .white-bg input:focus,
.pw-violet .white-bg .select2-container--open .selection > span,
.pw-violet .select2-container--default .select2-dropdown,
.pw-violet .select2-dropdown input span,
.pw-violet .modal-content textarea:focus,
.pw-violet .modal-content input:focus,
.pw-violet .custom-file-label,
.pw-violet .tab-content select:focus,
.pw-violet .tab-content input:focus{
	box-shadow: 0 0 0 0.05rem var(--pw-violet);
}
// ---- VIOLET - FINE

// ---- YELLOW - INIZIO

.pw-yellow .table-responsive .bg-white{
	background-color: transparent !important;
}

.pw-yellow .table-responsive th{
	text-align: center !important;
}

.pw-yellow .table-responsive th a{
	justify-content: center;
}

body.pw-yellow{
	background-color: var(--pw-white);
	background-image: none;
}

.pw-yellow .content-wrapper,
.pw-yellow .mobile-content-row{
	background-color: var(--pw-white);
	/*background-color: var(--pw-yellow-lightest-t20);*/
}

.pw-yellow.pw-switch .content-wrapper,
.pw-yellow.pw-switch .mobile-content-row{
	background-color: var(--pw-yellow-lightest-t20);
	background-image: url('/img/background/wave-full-background-hd-t50.png');
}

.pw-yellow .white-bg{
	background-color: transparent;
	border: none;
}

.pw-yellow .mobile-content-row .white-bg{
	background-color: var(--pw-white);
	/*background-color: var(--pw-yellow-t02);*/
}

.pw-yellow .page-helper {
    border: 2px dashed var(--pw-yellow) !important;
	background-color: var(--pw-yellow-t30);
	color: var(--pw-gray-darkest);
}

.pw-yellow .container-fluid p,
.pw-yellow .container-fluid a,
.pw-yellow .container-fluid .page-title,
.pw-yellow .container-fluid .page-title-number,
.pw-yellow .container-fluid .register_page label{
	color:var(--pw-yellow);
}

.pw-yellow .container-fluid h1{
	color:var(--pw-yellow-darkest);
}

.pw-yellow .container-fluid a{
	color: var(--pw-yellow-darkest);
}

.pw-yellow .card{
	border-color: transparent !important;
}

.pw-yellow .card-header{
	background: transparent;
	background-color: transparent;
	color: var(--pw-yellow-dark);
	border-bottom: none;
}

.pw-yellow .card-title {
    float: inherit;
}

.pw-yellow a:hover,
.pw-yellow .btn-link:hover {
    color: var(--pw-yellow-darkest);
	text-decoration: none;
	font-weight: 700;
}

.pw-yellow-nav-back{
	background: var(--pw-yellow-light) !important;
}

.pw-yellow .btn-success,
.pw-yellow .btn.btn-petwave,
.pw-yellow #mapHelper{
	color: var(--pw-white);
	background-color: var(--pw-yellow);
	border-color: var(--pw-yellow);
	border-radius: 0.5rem;
}

.pw-yellow .btn.btn-petwave:not(:disabled):not(.disabled):active,
.pw-yellow .btn.btn-petwave:not(:disabled):not(.disabled).active,
.pw-yellow .show > .btn-petwave.dropdown-toggle{
	background-color: var(--pw-yellow-dark);
}

.pw-yellow .btn-success:hover,
.pw-yellow .btn-success:active,
.pw-yellow .btn.btn-petwave:hover,
.pw-yellow .btn.btn-petwave.active,
.pw-yellow .btn.btn-outline-petwave:hover,
.pw-yellow .btn.btn-outline-petwave.active{
	background-color: var(--pw-yellow-darker);
    border-color: var(--pw-yellow-darker);
}

.pw-yellow .white-bg textarea:focus,
.pw-yellow .white-bg select:focus,
.pw-yellow .white-bg input:focus,
.pw-yellow .white-bg .select2-container--open .selection > span,
.pw-yellow .select2-container--default .select2-dropdown,
.pw-yellow .select2-dropdown input span,
.pw-yellow .modal-content textarea:focus,
.pw-yellow .modal-content input:focus,
.pw-yellow .custom-file-label,
.pw-yellow .tab-content select:focus,
.pw-yellow .tab-content input:focus{
	box-shadow: 0 0 0 0.05rem var(--pw-yellow);
}
// ---- YELLOW - FINE

// ---- BLUE - INIZIO
body.pw-blue{
	background-color: var(--pw-white);
	background-image: none;
}

.pw-blue .content-wrapper,
.pw-blue .pw-blue .content-wrapper{
	background-color: var(--pw-blue-lightest-t20);
}

.pw-blue .white-bg{
	background-color: transparent;
	border: none;
}

.pw-blue .page-helper {
    border: 2px dashed var(--pw-blue) !important;
	background-color: var(--pw-blue-t30);
	color: var(--pw-gray-darkest);
}

.pw-blue .container-fluid .page-title,
.pw-blue .container-fluid .page-title-number,
.pw-blue .container-fluid .register_page label{
	color:var(--pw-blue);
}

.pw-blue .container-fluid a{
	color:var(--pw-blue-dark);
}

.pw-blue .card{
	border-color:var(--pw-blue) !important;
}

.pw-blue .card .category{
	background-color: var(--pw-blue);
}

.pw-blue a:hover,
.pw-blue .btn-link:hover {
    color: var(--pw-blue-dark);
	text-decoration: none;
	font-weight: 700;
}

.pw-blue-nav-back{
	background: var(--pw-blue-light) !important;
}

.pw-blue .btn-success,
.pw-blue .btn.btn-petwave,
.pw-blue #mapHelper{
	color: var(--pw-white);
	background-color: var(--pw-blue);
	border-color: var(--pw-blue);
	border-radius: 0.5rem;
}

.pw-blue .btn.btn-petwave:not(:disabled):not(.disabled):active,
.pw-blue .btn.btn-petwave:not(:disabled):not(.disabled).active,
.pw-blue .show > .btn-petwave.dropdown-toggle{
	background-color: var(--pw-blue-dark);
}

.pw-blue .btn-success:hover,
.pw-blue .btn-success:active,
.pw-blue .btn.btn-petwave:hover,
.pw-blue .btn.btn-petwave.active,
.pw-blue .btn.btn-outline-petwave:hover,
.pw-blue .btn.btn-outline-petwave.active{
	background-color: var(--pw-blue-darker);
    border-color: var(--pw-blue-darker);
}

.pw-blue .white-bg textarea:focus,
.pw-blue .white-bg select:focus,
.pw-blue .white-bg input:focus,
.pw-blue .white-bg .select2-container--open .selection > span,
.pw-blue .select2-container--default .select2-dropdown,
.pw-blue .select2-dropdown input span,
.pw-blue .modal-content textarea:focus,
.pw-blue .modal-content input:focus,
.pw-blue .custom-file-label,
.pw-blue .tab-content select:focus,
.pw-blue .tab-content input:focus{
	box-shadow: 0 0 0 0.05rem var(--pw-blue);
}
// ---- BLUE - FINE

// ---- GRAY - INIZIO
body.pw-gray{
	background-color: var(--pw-white);
	background-image: none;
}

.pw-gray .content-wrapper,
.pw-gray .mobile-content-row{
	background-color: var(--pw-white);
	/*background-color: var(--pw-gray-lightest-t20);*/
}

.pw-gray .white-bg{
	background-color: transparent;
	border: none;
}

.pw-gray .page-helper {
    border: 2px dashed var(--pw-gray) !important;
	background-color: var(--pw-gray-t30);
	color: var(--pw-gray-darkest);
}

.pw-gray .container-fluid .page-title,
.pw-gray .container-fluid .page-title-number,
.pw-gray .container-fluid .register_page label{
	color:var(--pw-gray);
}

.pw-gray .container-fluid a{
	color:var(--pw-gray-dark);
}

.pw-gray .card{
	border-color: transparent !important;
}

.pw-gray .card .category{
	background-color: var(--pw-gray);
}

.pw-gray a:hover,
.pw-gray .btn-link:hover {
    color: var(--pw-gray-dark);
	text-decoration: none;
	font-weight: 700;
}

.pw-gray-nav-back{
	background: var(--pw-gray) !important;
}

.pw-gray.nav-item{
	background: var(--pw-gray-t35);
}

.pw-gray.nav-item a.nav-link,
.pw-gray.nav-item a.nav-link i{
	color: var(--pw-gray-darkestextra) !important;
}

.pw-gray .btn-success,
.pw-gray .btn.btn-petwave,
.pw-gray #mapHelper{
	color: var(--pw-white);
	background-color: var(--pw-gray);
	border-color: var(--pw-gray);
	border-radius: 0.5rem;
}

.pw-gray .btn.btn-petwave:not(:disabled):not(.disabled):active,
.pw-gray .btn.btn-petwave:not(:disabled):not(.disabled).active,
.pw-gray .show > .btn-petwave.dropdown-toggle{
	background-color: var(--pw-gray-dark);
}

.pw-gray .btn-success:hover,
.pw-gray .btn-success:active,
.pw-gray .btn.btn-petwave:hover,
.pw-gray .btn.btn-petwave.active,
.pw-gray .btn.btn-outline-petwave:hover,
.pw-gray .btn.btn-outline-petwave.active{
	background-color: var(--pw-gray-darker);
    border-color: var(--pw-gray-darker);
}

.pw-gray .white-bg textarea:focus,
.pw-gray .white-bg select:focus,
.pw-gray .white-bg input:focus,
.pw-gray .white-bg .select2-container--open .selection > span,
.pw-gray .select2-container--default .select2-dropdown,
.pw-gray .select2-dropdown input span,
.pw-gray .modal-content textarea:focus,
.pw-gray .modal-content input:focus,
.pw-gray .custom-file-label,
.pw-gray .tab-content select:focus,
.pw-gray .tab-content input:focus{
	box-shadow: 0 0 0 0.05rem var(--pw-gray);
}
// ---- GRAY - FINE

// ---- PINK - INIZIO

.pw-pink .table-responsive .bg-white{
	background-color: transparent !important;
}

.pw-pink .table-responsive th{
	text-align: center !important;
}

.pw-pink .table-responsive th a{
	justify-content: center;
}

body.pw-pink{
	background-color: var(--pw-white);
	background-image: none;
}

.pw-pink .content-wrapper,
.pw-pink .pw-pink .content-wrapper{
	background-color: var(--pw-pink-lightest-t20);
}

.pw-pink .white-bg{
	background-color: transparent;
	border: none;
}

.pw-pink .mobile-content-row .white-bg{
	background-color: var(--pw-white);
	/*background-color: var(--pw-pink-t02);*/
}

.pw-pink .page-helper {
    border: 2px dashed var(--pw-pink) !important;
	background-color: var(--pw-pink-t30);
	color: var(--pw-gray-darkest);
}

.pw-pink .container-fluid p,
.pw-pink .container-fluid a,
.pw-pink .container-fluid .page-title,
.pw-pink .container-fluid .page-title-number,
.pw-pink .container-fluid .register_page label{
	color:var(--pw-pink-darkest);
}

.pw-pink .container-fluid h1{
	color:var(--pw-pink-darkest);
}

.pw-pink .container-fluid a{
	color: var(--pw-pink-darkest);
}

.pw-pink .card{
	border-color: transparent !important;
}

.pw-pink .card-header{
	background: transparent;
	background-color: transparent;
	color: var(--pw-pink-dark);
	border-bottom: none;
}

.pw-pink .card-title {
    float: inherit;
}

.pw-pink a:hover,
.pw-pink .btn-link:hover {
    color: var(--pw-pink-darkest);
	text-decoration: none;
	font-weight: 700;
}

.pw-pink-nav-back{
	background: var(--pw-pink-light) !important;
}

.pw-pink .btn-success,
.pw-pink .btn.btn-petwave,
.pw-pink #mapHelper{
	color: var(--pw-white) !important;
	background-color: var(--pw-pink);
	border-color: var(--pw-pink);
	border-radius: 0.5rem;
}

.pw-pink .btn.btn-petwave:not(:disabled):not(.disabled):active,
.pw-pink .btn.btn-petwave:not(:disabled):not(.disabled).active,
.pw-pink .show > .btn-petwave.dropdown-toggle{
	background-color: var(--pw-pink-dark);
}

.pw-pink .btn-success:hover,
.pw-pink .btn-success:active,
.pw-pink .btn.btn-petwave:hover,
.pw-pink .btn.btn-petwave.active,
.pw-pink .btn.btn-outline-petwave:hover,
.pw-pink .btn.btn-outline-petwave.active{
	background-color: var(--pw-pink-darker);
    border-color: var(--pw-pink-darker);
}

.pw-pink .white-bg textarea:focus,
.pw-pink .white-bg select:focus,
.pw-pink .white-bg input:focus,
.pw-pink .white-bg .select2-container--open .selection > span,
.pw-pink .select2-container--default .select2-dropdown,
.pw-pink .select2-dropdown input span,
.pw-pink .modal-content textarea:focus,
.pw-pink .modal-content input:focus,
.pw-pink .custom-file-label,
.pw-pink .tab-content select:focus,
.pw-pink .tab-content input:focus{
	box-shadow: 0 0 0 0.05rem var(--pw-pink);
}
// ---- PINK - FINE

/*Background Color*/

.bg-pw-primary {
	background-color:var(--pw-primary);
	color: white;
}

.bg-pw-primary-light {
	background-color:var(--pw-primary-light);
}

.bg-pw-primary-lighter {
	background-color:var(--pw-primary-lighter);
}

.bg-pw-primary-lightest {
	background-color:var(--pw-primary-lightest);
}

.bg-pw-primary-dark {
	background-color:var(--pw-primary-dark);
	color: white;
}

.bg-pw-primary-darker {
	background-color:var(--pw-primary-darker);
	color: white;
}

.bg-pw-primary-darkest {
	background-color:var(--pw-primary-darkest);
	color: white;
}

.bg-transparent {
	background-color:var(--pw-black-3) !important;
}

.bg-transparent-light {
	background-color:var(--pw-black-1) !important;
}

.bg-transparent-force {
	background-color:transparent !important;
}

.bg-white-force {
	background-color: var(--pw-white) !important;
}

.pw-red {
	color: var(--pw-red);
}

.pw-gold {
	color: var(--pw-gold);
}

.pw-silver {
	color: var(--pw-silver);
}

.mg-05 {
	margin-left: .5rem;
}

.alert-petwave {
    color: #fff;
    background: var(--pw-primary);
    border-color: var(--pw-primary);
}

/* Light mode */
body {
	margin: 0;
	font-family: 'Poppins', sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: var(--pw-gray-dark);
	text-align: left;
	background-color: var(--pw-gray-light);
	background-image: url(/img/homenew/background01.jpg);
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	/*
	background-repeat: repeat;
	background-size: 150px;
	background-position: 0rem;
	background-attachment: fixed;
	word-wrap: break-word;
	*/
}

body a, body .btn-link {
	color: var(--pw-primary-dark);
}

body a:hover, body .btn-link:hover {
	color: var(--pw-primary-darkest);
	/*text-decoration: none;*/
}

body a[disabled] {
	color: var(--pw-gray);
	pointer-events: none;
	cursor: default;
}

.container {
	max-width: 80%;
	min-width: 1250px;
}

.mobile{
	background-color: var(--pw-primary-darkest);
}

.mobile .container {
	max-width: 100%;
	min-width: 95vw !important;
	padding: 0rem;
}

.mobile .content {
	padding-left: 0rem;
	padding-right: 0rem;
}

.white-bg {
	padding: .5rem;
	background-color: var(--pw-white);
	border-radius: 0.5rem;
	/*
	border: var(--pw-gray-light) solid 1px;
	border-radius: .25rem;
	*/
}

.white-bg.read a {
	color: var(--pw-gray) solid 1px;
	background-color: var(--pw-black-1);
}


.no-bg {
	background: none !important;
}

.none {
	display: none;
}

.d-contents{
	display: contents !important;
}

.invisible {
	opacity: none;
}

.noDecoration:hover {
	text-decoration: none;
}

.nowrap {
	white-space: nowrap;
}

.inline {
	display: inline;
}

.small {
	font-size: 0.75em;
}

.verysmall {
	font-size: 0.5em;
}

.block {
	display: block;
}

.male {
	color: var(--pw-male) !important;
}

.male:hover {
	color: var(--pw-male-hover);
}
/*
.male::after {
	content: " ♂" !important;
	color: var(--pw-male) !important;
	font-weight: bold !important;
}

.female::after {
	content: " ♀" !important;
	color: var(--pw-female) !important;
	font-weight: bold !important;
}
*/

.female {
	color: var(--pw-female) !important;
}

.female:hover {
	color: var(--pw-female-hover);
}

.neutral {
	color: var(--pw-primary);
}

.neutral:hover {
	color: var(--pw-primary);
}

/* NAVIGATION */

.navigation-bs {
	background-color: var(--pw-primary);
}

.navigation-bs .container {
	width: 100%;
	margin-bottom: 0rem;
	text-align: center;
}

.navigation-bs a {
	color: var(--pw-white);
}

.navigation-bs a:hover {
	text-decoration: none;
	color: var(--pw-gray-empty);
}

.navigation-bs .dropdown-item {
	color: var(--pw-gray-dark);
}

.mobile .navigation-bs input[type=text] {
	margin-top: .6rem;
}

.navigation-bs input[type=text] {
	border: none;
	font-size: 1rem;
	padding: .5rem;
	margin-top: .8rem;
	border-radius: 1.5rem;
	display: inline-block;
	width: 100%;
}

.navigation-bs .title {
	text-align: left;
	display: inline-block !important;
	font-size: 1.8rem;
	font-family: 'Nunito', sans-serif;
	font-weight: 200;
	line-height: 1em;
}

.navigation-bs .logo {
	max-height: 2.5rem;
	margin-top: .75rem;
}

.mobile .navigation-bs .badge {
	position: absolute;
}

.main-header #search {
	border-radius: 1.5rem 0rem 0rem 1.5rem !important;
}

.main-header #searchbutton {
	border-radius: 0rem 1.5rem 1.5rem 0rem !important;
}

.navigation-bs .badge {
	display: inline-block;
	font-family: Tahoma, Geneva, Verdana, sans-serif;
	padding: .1rem;
	font-size: .9rem;
	font-weight: 600;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: text-top;
	border-radius: .25rem;
	/*border:0.1rem solid white;*/
	background-color: var(--pw-gray-dark);
	color: var(--pw-white);
	min-width: 1rem;
}

.navigation-bs .material-icons {
	padding: .5rem;
	font-size: 1.3rem;
	padding-top: 1rem;
}

.navbar {
	/*
	background: var(--pw-primary);
	*/
	background: transparent !important;
	background-color: transparent !important;
}

.navbar-light {
	background: transparent !important;
	background-color: transparent !important;
}

.navbar-light .navbar-nav .nav-link{
	color: #1C7C54;
}

.navbar-expand{
	background-image: url(/img/homenew/background01-top.jpg) !important;
	background-repeat: no-repeat !important;
	background-size: cover !important;
	border-bottom: none !important;
}

.login_register_page .navbar {
	background: var(--pw-white) !important;
}

body:not(.mobile) .navbar {
	min-width: 1030px;
}

.navbar-brand {
	color: white !important;
}

.brand-container {
	max-height: 3rem;
}

.navbar-badge {
	top:0rem;
}

.navbar-nav .nav-item i.active {
	color: var(--pw-white);
}

a.nav-link.admin p, a.nav-link.admin i.nav-icon::before {
	color: var(--pw-yellow-light) !important;
}

.notification-badge-petwave {
	color: var(--pw-primary-darkest);
    background-color: var(--pw-gray-post);
    font-weight: bolder !important;
	border-radius: 60px;
    padding: 4px;
    top: 2px;
    right: 0px;
}

.badge-petwave {
	background-color: var(--pw-primary);
    color: var(--pw-primary-darkest);
    font-weight: bolder !important;
}

.badge-petwave:hover {
	background-color: var(--pw-primary-darkest);
	color: var(--pw-primary-lighter);
    font-weight: bolder !important;
}

.badge-userTag {
	background-color: var(--pw-gray);
    color: var(--pw-gray-darkest);
    font-weight: bolder !important;
}

.badge-userTag:hover {
	background-color: var(--pw-gray-dark);
	color: var(--pw-gray-lighter);
    font-weight: bolder !important;
}

.badge-petMaleTag {
	background-color: var(--pw-blue);
    color: var(--pw-blue-darkest);
    font-weight: bolder !important;
}

.badge-petMaleTag:hover {
	background-color: var(--pw-blue-darkest);
	color: var(--pw-blue-light);
    font-weight: bolder !important;
}

.badge-petFemaleTag {
	background-color: var(--pw-magenta-light);
    color: var(--pw-magenta-darkest);
    font-weight: bolder !important;
}

.badge-petFemaleTag:hover {
	background-color: var(--pw-magenta-darkest);
	color: var(--pw-magenta-light);
    font-weight: bolder !important;
}

.rounded50 {
	border-radius: 50% !important;
}

.roundedQR {
	border-radius: 50% 50% 0 50% !important;
}

.rounded-img {
	border-radius: 50% !important;
	padding: .5rem;
}

.followed-list .list-text {
	padding-top: 2.5rem;
}

.mobile .list-text {
	padding-top: .2rem;
}

.list-text {
	padding-top: 1.5rem;
}

.fs-def {
    font-size: 1.125rem;
}

.fs-1rem {
    font-size: 1rem;
}

.fs-0 {
    font-size: 0rem;
}

/**/

.page-content {
	margin-top: 5.5rem;
	margin-bottom: 1rem;
}

.content-wrapper {
    background: none;
}

.content-wrapper .content {
    margin-top: 3rem;
}

.mobile .page-content {
	margin-top: 4rem;
	margin-bottom: 1rem;
}

/* SIDEBAR ADMINLTE */

.pointer {
	cursor: pointer;
}

.sidebar-dark-primary {
	background-color: var(--pw-white);
}

.layout-navbar-fixed .wrapper .sidebar-dark-primary .brand-link:not([class*=navbar]) {
    background-color: var(--pw-white); /*Background intestazione sidebar*/
	color: var(--pw-primary-dark);
}

.sidebar {
	list-style: none;
	margin: 0rem;
}


.sidebar li ul {
	padding-left: .9rem;
	list-style: none;
}

.sidebar-dropdown a {
	color: var(--pw-primary-dark) !important
}

.sidebar-dropdown {
	list-style: none;
	padding-left: .5rem;
}

.sidebar-dropdown li ul {
	list-style: none;
	padding-left: 1rem;
}

.sidebar-dropdown-container {
	width: 15rem;
}
/* /SIDEBAR ADMINLTE */

/* POST INDEX - DESKTOP CARD */

.post-index-suggested-card{
	border-radius: 10px;
	-webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
	box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0)
}

/* /POST INDEX - DESKTOP CARD */

/* PROFILE */

/*.profile-picture-overlay {
	opacity: 0;
	height: 5rem;
	margin-top: -5rem;
}

.profile-picture-overlay:hover {
	opacity: 100;
	color: white;
	text-shadow: 1px 1px 2px black
}*/

.profile-data {
	align-content: flex-start;
}

.profile-data p,
.profile-data .owners {
	margin-bottom: .3rem;
}

.owners p .owner-single:last-of-type::after {
	content: none;
}

.owners p .owner-single::after {
	content: ", ";
}

.profile-data ul {
	list-style: none;
	padding-left: 0rem;
	display: inline;
}

.profile-data ul li {
	display: inline;
}

.profile-data ul li::after {
	content: ","
}

.profile-data ul li:last-child::after {
	content: none;
}

.page-title {
	font-size: 1.5rem;
	font-weight: bold;
	color: var(--pw-primary);
}

.page-title-number {
	font-size: 1.8rem;
	font-weight: bold;
	color: var(--pw-primary);
}

.page-title img {
	max-height: 2.5rem;
	margin-right: .5rem;
}

.profile-interactions {
	position: absolute;
	bottom: 0rem;
	width: calc(100% - 1.5rem);
}

.mobile .profile-interactions {
	text-align: center;
	position: initial;
	bottom: 0rem;
	width: 100%;
}

.profile-edit {
	position: absolute;
	right: 0;
	top: 0;
}

.profile-related-single {
	padding-top: .5rem;
}

.profile-related {
	margin-bottom: .5rem;
}

.profile-related-pic {
	width: 100%;
	border-radius: .25rem;
}

.profile-related-info {
	padding-left: .5rem !important;
	padding-right: .5rem !important;
}

.profile-gallery {
	margin-left: .5rem;
}

.mobile .profile-gallery {
	margin-left: 0rem;
	margin-bottom: 0.5rem;
}


.profile-gallery img {
	padding: .1rem;
}

/* POST & COMMENTS */

#previewuploadFile,
#previewuploadFileMobile {
	padding-bottom: .5rem;
	text-align: center;
}

#previewuploadMale img,
#previewuploadFemale img,
#previewuploadDraw img {
	max-width: 100%;
}

#cancelUpload {
	color: white;
	position: absolute;
	z-index: 9;
}

#cancelUpload:hover {
	color: red;
	text-decoration: none;
}

#previewuploadFile video,
#previewuploadFile img,
#previewuploadFileMobile video {
	max-width: 100%;
	max-height: 40vh;
}

#previewuploadFileMobile img {
	max-width: 100%;
	max-height: 31vh;
}

/*
.mobile .add-post .mdi {
	font-size: 2rem;
}
*/

.post-single {
	padding: .5rem;
	margin-bottom: .5rem;
}

.post-content .post-single {
	background-color: var(--pw-gray-light);
}

.mobile .post-single {
	padding: .5rem;
	margin-bottom: .5rem;
}

.post-single .material-icons {
	font-size: 1rem;
}

.post-single .reaction-bar {
	padding-top: .5rem;
}

.reaction-bar .material-icons {
	color:var(--pw-gray);
}

.reaction-bar a:not(.underlined) {
	color:var(--pw-gray-dark);
	font-size: 1rem;
}

.reaction-bar a.post-unlike,
.reaction-bar a.times-liked {
	color: var(--pw-primary);
	cursor: pointer;
}

.white-bg textarea:focus,
.white-bg select:focus,
.white-bg input:focus,
.white-bg .select2-container--open .selection>span,
.select2-container--default .select2-dropdown,
.select2-dropdown input span,
.modal-content textarea:focus,
.modal-content input:focus,
.custom-file-label,
.tab-content select:focus,
.tab-content input:focus {
	outline: none;
	box-shadow: 0 0 0 0.05rem var(--pw-primary);
}

.input-post:focus {
	right: 3px !important;
}

.input-comment:focus {
	/*
	right: 3px !important;
	*/
	border: none;
	box-shadow: none !important;
}

.input-post {
	padding: .5rem !important;
	border: 1px var(--pw-gray-empty) solid;
	border-radius: .25rem .5rem 0rem .5rem;
}

.input-comment {
	padding: .5rem !important;
	border: none;
	/*
	border: 1px var(--pw-gray-empty) solid;
	border-radius: .25rem .5rem 0rem .5rem;
	*/
}

.post-header .col-1 img {
	border-radius: .25rem;
	width: 80%;
	text-align: center
}

.post-header .col-10 {
	padding-left: 0rem;
}

.post-options {
	color: var(--pw-primary);
}

.post-options:hover {
	color: var(--pw-primary);
}

.post-options .material-icons {
	text-decoration: none;
}

.post-options ul {
	padding-left: .5rem;
	list-style: none;
}

.post-content {
	border-top: 1px var(--pw-gray-empty) solid;
	padding-top: .5rem;
}

.input-pettag, .input-geotag, .input-image {
	line-height: 1rem;
	font-size: 1rem;
}

.post-submit{
	color: white;
	border-radius: 0rem 0rem .5rem .5rem;
	background-color: var(--pw-primary);
	border: 1px var(--pw-gray-empty) solid;
}

.comment-submit {
	color: var(--pw-primary);
	font-weight: normal;
	background-color: transparent;
	border: none;
}


.post-submit:hover{
	color: var(--pw-white);
	background-color: var(--pw-primary-dark);
}

.comment-submit:hover {
	color: var(--pw-primary-dark);
	font-weight: bold;
}

#post-new-message, .input-comment {
	margin-bottom: -.5rem;
}

.comment-column .comment-section {
	height: 70vh;
}

.reaction-bar .first-row,
.reaction-bar .second-row {
	border-bottom: 1px var(--pw-gray-empty) solid;
}

.comment-section .show-more {
	padding-left: .5rem;
}

.post-resume {
	border-bottom: 1px var(--pw-gray-lightest-t30) solid;
}

.comment-single {
	padding-top: .5rem;
}

.post-author-picture{
	border-radius: .25rem;
	width: 80%;
	max-width: 80%;
}

.comment-single img {
	border-radius: .25rem;
	width: 60%;
	max-width: 60%;
}

.comment-text {
	margin-bottom: .5rem;
	margin-right: .5rem;
	font-size: 0.9375rem;
	background: #21B9A50D;
    padding: 10px;
    border-radius: 18px;
}

.comment-like,
.comment-liked {
	color: var(--pw-primary);
}

/* ADS */

.adv-btn {
	color: var(--pw-gray-dark);
	border-radius: .25rem;
	background-color: var(--pw-gray-empty);
	border: 1px var(--pw-gray-empty) solid;
}

.adv-btn.confirm:hover {
	color: var(--pw-white);
	background-color: var(--pw-primary);
	border: 1px var(--pw-primary-dark) solid;
}

.adv-btn.draft:hover {
	color: var(--pw-white);
	background-color: var(--pw-orange);
	border: 1px var(--pw-orange-dark) solid;
}

.adv-btn.delete:hover {
	color: var(--pw-white);
	background-color: var(--pw-red);
	border: 1px var(--pw-orange-dark) solid;
}

.adv-btn.deleted {
	color: var(--pw-red);
	background-color: var(--pw-white);
	border: 1px var(--pw-red) solid;
}

.adv-btn.pay:hover {
	color: var(--pw-white);
	background-color: var(--pw-blue-light);
	border: 1px var(--pw-blue) solid;
}

.adv-btn.copy:hover {
	color: var(--pw-white);
	background-color: var(--pw-yellow);
	border: 1px var(--pw-gold) solid;
}

.adv-single {
	margin-bottom: .5rem;
}

.adv-single>div {
	margin-left: .5rem;
}

.adv-single-map>div {
	margin-left: .25rem;
	margin-right: .25rem;
}

.product-price-sale {
	color: var(--pw-primary);
	font-weight: bold;
}

.product-price {
	color: var(--pw-gray);
	text-decoration: line-through;
}

h4.adv-title {
	font-size: 1.1rem;
	padding-top: .5rem;
}

h4.adv-title:last-child,h5.adv-vendor:last-child,h6.adv-info:last-child {
	margin-bottom: 0rem;
}

.page-title .event,
.page-title-number .event,
.color-event,
.color-event:hover,
.adv-title.event,
.adv-subtitle.event {
	color: var(--pw-violet) !important;
}

.page-title .zootechnics,
.page-title-number .zootechnics,
.color-zootechnics,
.color-zootechnics:hover,
.adv-title.zootechnics,
.adv-subtitle.zootechnics {
	color: var(--pw-green) !important;
}

.page-title .professionist,
.page-title-number .professionist,
.color-professionist,
.color-professionist:hover,
.adv-title.professionist,
.adv-subtitle.professionist {
	color: var(--pw-magenta) !important;
}

.page-title .petfriendly,
.page-title-number .petfriendly,
.color-petfriendly,
.color-petfriendly:hover,
.adv-title.petfriendly,
.adv-subtitle.petfriendly {
	color: var(--pw-orange) !important;
}

.page-title .misc,
.page-title-number .misc,
.color-misc,
.color-misc:hover,
.adv-title.misc,
.adv-subtitle.misc {
	color: var(--pw-primary) !important;
}

.page-title .publicplace,
.page-title-number .publicplace,
.color-publicplace,
.color-publicplace:hover,
.adv-title.publicplace,
.adv-subtitle.publicplace {
	color: var(--pw-blue-light) !important;
}

/* MAP */

.clusterIcon {
	background-color:var(--pw-primary);
	color:white;
	min-width:50px;
	min-height:50px;
	position: relative;
	left: -25px;
	text-align: center;
	font-size: 1.5rem;
	padding-top:.4rem;
	border-radius: 50%;
	border: solid .15rem white;
}

#hiddenField {
	background-color: var(--pw-white);
	position: absolute;
	width: 50vw;
	top: 3rem;
	border-radius: .5rem;
	border:1px solid var(--pw-primary);
}

.mobile #hiddenField {
	width: 80vw;
}

#search-before {
	border-top-left-radius: .5rem;
	border-top-right-radius: .5rem;
}

.search-help{
	font-size: 1rem;
}

.mobile .search-help{
	font-size: 0.6rem;
}

#search-results {
	overflow-y: scroll;
	height: 30vh;
	text-align: left;
	padding-left: .5rem;
	border: var(--pw-gray-dark) solid 1px;
}

.single-geotag-result img {
	max-height: 3rem;
	max-width: 3rem;
}

.single-geotag-result:last-child {
	border-bottom: none;
}

.single-geotag-result {
	display: flex;
	color: black !important;
	border-bottom: 1px dashed var(--pw-primary);
	padding: .3rem;
	margin: 0rem;
}
.single-geotag-result span {
	padding-left: .3rem;
}

.single-geotag-result:hover,
.single-geotag-result:focus {
	background-color: var(--pw-primary-lighter);
	outline: none;
}

.searchbar {
	border-radius: .25rem;
	padding-left: .5rem;
	padding-right: .5rem;
	padding-top:.25rem;
	padding-bottom:.25rem;
	border-color: var(--pw-gray-light);
}

#search-more {
	background: white;
	height: 2.5rem;
	border-bottom-left-radius: .5rem;
	border-bottom-right-radius: .5rem;
	border: var(--pw-gray-dark) solid 1px;
	padding-top: .5rem;
	cursor: pointer;
}
#search-more:hover {
	background: var(--pw-primary-dark);
	color: var(--pw-white);
}

.missing-pet.profile-picture {
	border-radius: 2.5rem;
	padding: 1rem;
}

.missing-pet.info {
	padding-left: .5rem;
}

.nearby-search-results,
.missing-search-results,
.mating-search-results {
	margin-bottom: .5rem;
}

.nearby-search-results.single,
.missing-search-results.single,
.mating-search-results.single {
	cursor: pointer;
}

#nearby-map {
position: relative
}

.checkbox_icon {
	max-height: 2.5rem;
	padding: .2rem;
}

#mapid{
	z-index: 100;
	height: 60vh;
}

#mapHelper {
	z-index: 150;
	position: absolute;
	right: .5rem;
	top: 4.75rem;
	color: black;
	background-color: var(--pw-primary);
	display: none;
	color: var(--pw-white);
	font-weight: bold;
	border-bottom-left-radius: .5rem;
}

#refreshButton {
	z-index: 150;
	position: absolute;
	right: 1rem;
	bottom: 1rem;
	border: dashed 1px white;
}

#select_all_label {
	display: inline-block;
	font-size: 1rem;
	padding-left: 1rem;
}

#nearby-search-results,
#missing-search-results,
#mating-search-results {
	max-height: 75vh;
	display: none;
	overflow: auto;
	align-content: flex-start;
}
#nearby-search-results div:last-of-type hr,
#missing-search-results div:last-of-type hr,
#mating-search-results div:last-of-type hr {
	display: none;
}

.missing-page .leaflet-marker-icon:not(.b-none),
.mating-page .leaflet-marker-icon:not(.b-none) {
	border-radius: .25rem;
	border: solid .15rem white;
}

.leaflet-control-attribution {
	display: none;
}

.leaflet-popup-content-wrapper {
	border-radius: .25rem !important;
	padding: .25rem !important;
}

.leaflet-popup-content {
	margin: .5rem !important;
}

.leaflet-popup-content a {
	margin: 0px !important;
	font-weight: bold;
}

.leaflet-popup-content p {
	margin: 0px !important;
}

.popover ul {
	list-style: none;
	padding: .1rem !important;
	margin-bottom: 0rem;
}

.popover.fade.bs-popover-right.show{
	border: solid var(--pw-primary);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
	border-right-color: var(--pw-primary);
}

.places-categories input {
	margin-right: .5rem;
}

.places-categories label {
	display: block;
	margin-bottom: 0rem;
}

@media screen and (min-width: 820px) {
.profile-interactions-event {
	position: relative;
	margin-top: 3rem;
	width: calc(100% - 1.5rem);
}
}
/*
.mobile h1 {
	font-size: 2em;
}
*/

.mobile h1 {
	font-size: 1.5em;
}

$breakpoint-small-iphone: 375px;
@media (min-width: $breakpoint-small-iphone) {
	.mobile h1 {
		font-size: 1.2em;
	}
}

.mobile h2 {
	font-size: 1.75em;
}

.mobile h3 {
	font-size: 1.5em;
}

.mobile h4 {
	font-size: 1.25em;
}

.mobile .mating-categories div {
	display: block;
	margin-left: 0rem;
}
.mobile .mating-categories label {
	margin-bottom: 0rem;
}

.mating-categories div {
	display: inline-block;
	margin-bottom: 0rem;
	margin-left: 1rem;
}

.mating-categories label:first-child {
	margin-left: 0rem;
}
.mating-categories input {
	margin-right: .25rem;
}

.search-map {
	margin-bottom: .5rem;
}

/* SHOP */

.shop-header {
	margin-bottom: .5rem;
}

.shop-categories {
	margin-bottom: .5rem;
	text-align: center;
}

.shop-banner {
	margin-bottom: .5rem;
}

.shop-slider {
	margin-bottom: .5rem;
}

.shop-recommended {
	text-align: center;
	margin-bottom: .5rem;
}

.shop-recent {
	text-align: center;
	margin-bottom: .5rem;
}

.shop-section {
	text-align: center;
	margin-bottom: .5rem;
}

.cart-page .table td {
	vertical-align: middle;
}

.shop-categories .image {
	margin: 5%;
    border-radius: 5px;
	box-shadow: 0 4px 8px 0 var(--pw-black-2), 0 6px 20px 0 var(--pw-black-1);
}

.shop-categories .name {
	text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    letter-spacing: 0.08em;
	margin-top: 0.15rem;
	/*margin-bottom: 1.3rem;*/
	font-size: 1rem;
}

.shop-categories-card{
	border-radius: 10px;
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
    box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
	cursor: pointer;
}

.shop-categories-card .card-img-top{
	border-radius: 10px;
}

.shop-category-image{
	border-radius: 10px;
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
    box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
	cursor: pointer;
}

/* Product */

.shop-product .title {
	font-size: 1.5rem;
	font-weight: bold;
	color: var(--pw-primary);
}

.shop-product .right {
	text-align: right;
}

.shop-product .info {
	margin-left: 3rem;
	padding-top: 4rem;
	padding-bottom: 2rem;
	border-top: 1px solid var(--pw-gray);
}

.shop-product .price {
	text-decoration: line-through;
}

.shop-product .price-sale {
	color: var(--pw-primary);
	font-weight: bold;
}

.shop-product .price-mass {
	color: var(--pw-primary);
	font-weight: bold;
}

#price-regular,
#price-mass-regular {
	text-decoration: line-through;
}

.product-gallery img {
	padding: .1rem
}

.product-gallery .row > :first-child img {
	border-bottom-left-radius: .25rem;
}

.product-gallery .row > :last-child img {
	border-bottom-right-radius: .25rem;
}

.mass-order {
	margin-top: 1rem;
	padding-top: .5rem;
	border-top: 1px solid var(--pw-gray);
}

.shop-product .text-left {
	padding-left: .5rem;
}

.shop-product .description {
	margin-top: 1rem;
	padding-top: .5rem;
	border-top: 1px solid var(--pw-gray);
}

.shop-product .mass-order,
.shop-product .description {
	padding-left: 1rem;
	padding-right: 1rem;
}

.mass-order .subtitle {
	padding-left: 2rem;
	margin-top: .5rem;
}

.shop-product button {
	color: var(--pw-white);
	background-color: var(--pw-primary);
	border-radius: .25rem;
	border: none;
	padding: .5rem;
}

.shop-product button:hover {
	background-color: var(--pw-primary-dark);
}

.shop-product button .material-icons {
	font-size: 1.2rem;
	position: relative;
	bottom: -.2rem
}

.shop-search .price-sale {
	color: var(--pw-primary);
	font-weight: bold;
}

.shop-search .price {
	text-decoration: line-through;
}

.shop-search .subtitle {
	color: var(--pw-gray);
}

.shop-search .sponsored {
	color: var(--pw-gray);
	margin-bottom: .2rem;
	margin-top: .5rem;
}

#openingHoursModal .table tbody + tbody {
	border-top: 1px solid var(--pw-primary);
}

#openingHoursModal .table th {
	color: var(--pw-primary);
}

.rating .material-icons {
	font-size: 1.2rem;
	position: relative;
	bottom: -.2rem;
}

#list_ctns {
	max-height: 65vh;
	overflow-y: scroll;
}

.search-result {
	padding: .5rem;
	margin: .25rem;
}

.register_page label,
.login_page label {
	padding-right: .5rem;
}

.register_page label {
	color:var(--pw-primary);
}

.register_page ul {
	list-style-type: none;
	padding-left: 1rem;
}

.register_page .pt {
	padding-top: .5rem;
}

.register_page .PetSpecialist,
.register_page .PetPlaces,
.register_page .FreeServices,
.register_page .OtherPlaces,
.register_page .PlaceOwnerFile {
    display: none;
}

.gender-field {
	padding: .5rem;
}

.form-group {
	margin-bottom: .5rem;
}

.form-control:disabled{
	color: var(--pw-gray-dark) !important;
}

.card-header {
	background: var(--pw-primary);
	color: var(--pw-white);
}

.btn.btn-petwave-gray {
	background-color: var(--pw-gray-dark);
	color: var(--pw-white);
	border-color: var(--pw-gray-dark);
}

.btn.btn-outline-petwave-gray {
	background-color: none;
	color: var(--pw-gray-dark);
	border-color: var(--pw-gray-dark);
}

.btn.btn-outline-petwave-black {
	background-color: none;
	color: var(--pw-gray-darkest);
	border-color: var(--pw-gray-darkest);
}

.btn.btn-outline-petwave-black:hover,
.btn.btn-outline-petwave-black.active {
	background-color: var(--pw-gray-darkest);
	color: var(--pw-white);
	border-color: var(--pw-gray-darkest);
}

.btn.btn-petwave-gray:hover,
.btn.btn-petwave-gray.active,
.btn.btn-outline-petwave-gray:hover,
.btn.btn-outline-petwave-gray.active {
	background-color: var(--pw-gray-dark);
	color: var(--pw-white);
	border-color: var(--pw-gray-light);
}

.btn.btn-petwave {
	background-color: var(--pw-primary);
	color: var(--pw-white);
	border-color: var(--pw-primary);
}

.btn.btn-outline-petwave {
	background-color: none;
	color: var(--pw-primary);
	border-color: var(--pw-primary);
}

.btn.btn-petwave:hover,
.btn.btn-petwave.active,
.btn.btn-outline-petwave:hover,
.btn.btn-outline-petwave.active {
	background-color: var(--pw-primary-darker);
	color: var(--pw-white);
	border-color: var(--pw-primary-darker);
}

.btn.btn-petwave:focus,
.btn.btn-petwave.focus {
    color: #fff;
    background-color: var(--pw-primary-dark);
    border-color: var(--pw-primary-dark);
    box-shadow: 0 0 0 0.2rem var(--pw-primary-darkest-transparent);
}

.btn.btn-petwave.disabled,
.btn.btn-petwave:disabled {
    color: #fff;
    background-color: var(--pw-primary);
    border-color: var(--pw-primary);
}

.btn.btn-petwave:not(:disabled):not(.disabled):active,
.btn.btn-petwave:not(:disabled):not(.disabled).active,
.show > .btn-petwave.dropdown-toggle {
    color: #fff;
    background-color: var(--pw-primary-dark);
    border-color: #1f6fb2;
}

.btn.btn-petwave:not(:disabled):not(.disabled):active:focus,
.btn.btn-petwave:not(:disabled):not(.disabled).active:focus,
.show > .btn-petwave.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem var(--pw-primary-darkest-transparent);
}

.bbg-no {
	background:none;
	border:none;
}

.underlined-inner:hover span {
	text-decoration: underline;
	cursor:pointer;
}

.underlined {
	text-decoration: underline;
}

.underlined:hover {
	text-decoration: underline;
	cursor:pointer;
}

.underlined:hover .mdi,
.underlined-inner:hover .mdi {
	color: var(--pw-primary-darker);
}

.underlined:hover .mdi::before {
	text-decoration: none;
}

/* Crowdfunding - Donation */

.progress.large {
	height: 1.5rem !important;
}

.progress.large div {
	padding-top: .2rem;
	padding-left: 1rem;
	font-size: 1.2rem;
}

/* Marketplace */

.marketplace-page .single-item {
	margin-bottom: .5rem;
	padding: 0rem .25rem 0rem .25rem;
}

.marketplace-page .single-item .item-sponsored {
	background-color: var(--pw-red);
	color: var(--pw-white);
	padding: .5rem;
	position: relative;
	top: .5rem;
	border-radius: 0rem 0rem .5rem 0rem;
}


.marketplace-page .single-item .item-upper-section img {
	margin-bottom: .5rem;
	margin-top: -1.45rem;
}

.marketplace-page .single-item .item-price-container {
	margin-top: -3rem;
	margin-right: .5rem;
	margin-left: .5rem;
}

.marketplace-page .single-item .item-price {
	background-color: black;
	border-radius: .25rem;
	color: var(--pw-white);
	padding: .5rem;
}

.marketplace-page .single-item .item-title {
	padding-top: .5rem;
}

/* Trending */

img.thumbnail {
	padding: .1rem;
}

/* Notifications & Friend Requests */

.read img {
	filter: grayscale(100%);
}

.notifications.read {
	background-color: var(--pw-gray-light);
}

.notifications:hover {
	background-color: var(--pw-black-1);
}

.notifications .text-sm {
	white-space: initial !important;
}

.notifications:hover p {
	color: var(--pw-primary-darker);
}

/* Thumbnails*/

.thumbnail-container{
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100%;
	background-clip: padding-box;
	background-color: #21b9a52d;
	border-color: var(--pw-gray-empty);
	margin: .1rem;
}

.thumbnail-container-user-gallery{
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-clip: padding-box;
	background-color: #21b9a52d;
	border-color: var(--pw-gray-empty);
	margin: .1rem;
	border-radius: 10px;
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
    box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
}

.gallery-thumbnail {
	width:100%;
	padding-top: 100%;
	position: relative;
}

.gallery-new-comment {
	position: absolute;
	bottom: 0rem;
}

.input-gallery-comment {
	padding: .5rem !important;
	border: 1px var(--pw-gray-empty) solid;
	border-radius: .25rem .5rem 0rem .5rem;
}

.comment-list {
	overflow: auto;
}

.gallery-modal-delete-button{
	position: absolute !important;
	right: 0;
}

.privacy-selector select {

	/* styling */
	background-color: white;
	border: none;
	display: inline-block;
	font: inherit;
	color: var(--pw-primary);
	line-height: 1rem;

	/* reset */
	margin: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;
}

a.report-button:hover {
	color: red;
	cursor: pointer;

}

.edit-post-header span {
	font-weight: normal;
	font-size: .9em;
}

.edit-post-header option {
	padding-left: 0rem;
	font-size: .9em;
}

.post-video, .post-image {
	text-align: center;
}

.post-video video,
.post-image img {
	max-height: 45vh;
	max-width: 100%;
}

@media screen and (max-width: 756px) {
	.adv-mobile img {
		max-height: 100%;
		max-width: 80%;
	}
}

body:not(.mobile) .modal-picture {
	max-height: 85vh;
	max-width: 100%;
}

.mobile .modal-picture {
	max-height: 80vh;
	max-width: 100%;
}

.modal-picture-container {
	margin-left:auto;
	margin-right:auto;
}

/* .modal-content>.row {
	min-height: 80vh;
} */

.modal-body.scrollable {
	max-height: 70vh;
	overflow-y: auto;
}

.userPetTagCheck {
	margin-left: .5rem;
	margin-right: .5rem;
	color: var(--pw-primary);
}

.userPetTagLabel {
	display: block;
}

.userPetTagLabel:last-of-type {
	margin: 0rem;
}

.clickable {
	cursor: pointer;
}

#post-tag, .post-tag-edit {
	display: none;
	padding-left: 0rem;
	list-style: none;
	z-index: 10;
}

#post-tag input, .post-tag-edit input {
	display: none;
}

#post-tag label, .post-tag-edit label {
	margin-bottom: 0rem;
}

#post-tag li, .post-tag-edit li {
	padding: .25rem;
	border: solid 1px var(--pw-gray-empty);
	background: white;
}

#post-tag li:first-child,
.post-tag-edit:first-child {
	border-radius: .25rem .5rem 0rem 0rem;
}

#post-tag li:last-child,
.post-tag-edit li:last-child {
	border-radius: 0rem 0rem .5rem .5rem;
}

#post-tag li:only-child,
.post-tag-edit li:only-child {
	border-radius: .25rem;
}

#post-tag li:hover,
#post-tag li.active,
.post-tag-edit li:hover,
.post-tag-edit li.active {
	background-color:var(--pw-primary);
	color: var(--pw-white);
}

#post-tag li:nth-child(2n+1),
.post-tag-edit li:nth-child(2n+1) {
	color: inherit;
}

.tag-thumbnail {
	max-width: 2rem;
	border-radius: .25rem;
}

.form-separator {
    margin-left: 0rem;
    margin-right: 0rem;
    margin-top: .5rem;
    margin-bottom: .5rem
}

.single-post-container {
    height: 100%;
    margin-bottom: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

/** Wiki **/

.floating-button-icon {
    background-color: var(--pw-primary);
    color: white;
    border-radius: 60px;
    padding: 0;
    height: 50px;
    width: 50px;
	border: var(--pw-white) 2px solid;
}

#save-button-container {
    z-index: 10;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
}

#translate-button-container {
    z-index: 10;
    position: fixed;
    bottom: 6rem;
    right: 2rem;
}

.wiki-categories {
	margin-bottom: .5rem;
	text-align: center;
}

.wiki-category {
	padding-bottom: 1rem;
	background-color: white;
	border-radius: .25rem;
}

.wiki-category h5 {
	text-transform: uppercase;
	margin-top: 1.5rem;
	margin-bottom: 1rem;
}

.mobile :not(.wiki-breadcrumb) .wiki-breadcrumb .wiki-breadcrumb {
	padding-left: 1.8rem;
}

.mobile .wiki-breadcrumb .wiki-breadcrumb::before {
	content: "⮡";
	color:var(--pw-gray);
	padding-right: .6rem;
}

body:not(.mobile) .wiki-breadcrumb:not(:last-child):after {
	content: ">";
	padding-left:.5rem;
	padding-right:.5rem;
	color:var(--pw-gray);
}

.wiki-breadcrumbs a {
	text-decoration: none;
}

.wiki-breadcrumbs a:hover {
	text-decoration: none;
}

.wiki-breadcrumb:hover::after {
	text-decoration: none;
	color:var(--pw-gray);
}

.section_title {
	text-transform: uppercase;
	font-weight: bold;
	color: var(--pw-primary-darker);
}

.wiki-section-title{
	text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	letter-spacing: 0.08rem;
	margin-top: 0.8rem !important;
	font-size: 1.8rem;
}

.wiki-section-margin{
	margin-top:1%;
}

.wiki-category-title{
	text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	letter-spacing: 0.08rem;
	font-size: 1.4rem;
}

/* Achievements */
.achievements .active {
	background-color: var(--pw-primary-dark);
	color: white;
}

.achievements .row {
	border-top: 1px solid lightgray;
	padding: .25rem;
}

/* Shopbar*/

.menu_Main_Element {
	text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	letter-spacing: 0.08rem;
	margin-top: 0.8rem;
}

.menu_Second_Element {
	text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	font-size: 0.8rem;
	letter-spacing: 0.08rem;
	margin-top: 0.3rem;
}

.shop-bar-title-margin-bottom {
	margin-bottom: 3%;
}

.shop-bar-middle-title {
	margin-top: 10%;
}

.shop-bar-price-input {
	width: 100% !important;
}

/* Petcoin Section */
.petcoin-manage table td,
.petcoin-manage table th {
	text-align: center;
	font-size: 1.2rem;
}

.mobile .petcoin-manage table td {
	line-height: 1;
	padding-top: .5rem;
	padding-bottom: .5rem;
	border-bottom: 1px solid var(--pw-gray-light);
}

.petcoin-manage table tr td:first-child {
	text-align: right;
}

.mobile th.petcoin-type {
	font-size: 1.2rem;
}

.petcoin-buy {
	background-color: var(--pw-gray-lightest-t30);
	border-radius: 1rem;
	padding: .5rem;
	border: solid var(--pw-primary) 1px;
}

.petcoin-buy.best {
	background-color: var(--pw-yellow-lightest-t30);
}

.petcoin-buy.bronze {
	background-color: var(--pw-orange-lightest-t30);
}

.petcoin-buy button {
	font-size: 1.7rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.petcoin-button,
.paypal-button,
.stripe-button {
	background: var(--pw-primary) !important;
	border-color: var(--pw-primary) !important;
	font-family:" Nunito", sans-serif !important;
	font-weight: 400 !important;
	box-shadow: none !important;
	font-size: 1rem !important;
	width: 90%;
	text-align: center;
	padding: .25rem !important;
}

/* Seller Control Panel */

.scp-table{
	vertical-align: middle !important;
}

.scp-table > p{
	margin-bottom: 0 !important;
}

/* Admin Panel */
.admin-panel .clickable-info-box {
	cursor: default;
}

#dataTable {
	display: block;
	overflow: auto;
	white-space: nowrap;
	max-height: 75vh;
}

#dataTable th { position: sticky !important; top: 0; background-color: var(--pw-white)}

.table-container .table {
	// display: block;
	overflow: auto;
	white-space: nowrap;
}

#dataTable thead,
#dataTable tbody {
	background-color: var(--pw-white);
}

#dataTable th,
#dataTable td {
	text-align: center;
	width: 100%;
	padding: .25rem .75rem;
	border-right: 1px var(--pw-black-1) solid;
}

/* Datatables - Align Searchbar Left */
#dataTable_wrapper .row .col-sm-12.col-md-6:first-child {
    display: none;
	border: 1px solid var(--pw-primary);
}

#dataTable_filter > label:nth-child(1) > input:nth-child(1) {
	border-radius: 1rem;
	margin-left: 1rem;
	padding-left: .5rem;
	padding-right: .5rem;
}

#dataTable_previous::after {
	margin-left: .5rem;
	margin-right: .5rem;
	content: "<";
	color: var(--pw-primary);
	font-size: 1rem;
	font-weight: bold;
}

#dataTable_next::before {
	margin-left: .5rem;
	margin-right: .5rem;
	content: ">";
	color: var(--pw-primary);
	font-size: 1rem;
	font-weight: bold;
}

#dataTable_paginate .paginate_button {
	padding-left: .15rem;
	padding-right: .15rem;
	cursor: pointer;
}

#dataTable_paginate .paginate_button.current {
	color: var(--pw-primary);
	font-weight: bold;
}

#dataTable_previous, #dataTable_next {
	font-size: 0rem;
}

/* Blog */

.single-answer:first-of-type {
	padding: .5rem;
	padding-top: 0rem;
    border-top: none;
}
.single-answer {
	padding: .5rem;
    border-top: 1px black solid;
}

.blog-description>p {
	width: 100%;
}

.tox-statusbar {
	display: none !important;
}

.submit-blog {
	text-align: center;
	white-space: nowrap;
}

.tinymce {
	max-height: 70vh;
	overflow-y: auto;
}

/* Login */

.login-blade .header {
	background-color: var(--pw-primary);
	max-height: 5rem;
}

.login-blade:not(.mobile) .logo {
	max-height: 7rem;
	padding: .75rem;
}

.login-blade.mobile .logo {
	max-height: 4rem;
}

.logo-big {
	max-height: 30rem;
	padding: 5rem;
}

.welcome-logo {
	margin-top: 6rem;
}

.mobile .welcome-logo {
	margin-top: 4rem;
}

.title {
	display: inline;
	font-size: 3rem;
}

form {
	margin-block-end: 0rem;
}

.form-group {
	margin-bottom: 0rem !important;
}

#login-button:not(.mobile) {
	padding: .4rem;
	margin-top: 2.4rem;
}

.dark-button {
	color: white;
	border: var(--pw-primary-dark) 1px solid; 	/*Darkgreen*/
	background-color: var(--pw-primary-darker); 	/*Lightgreen*/
	border-radius: .25rem;
}

.dark-button:hover {
	border: var(--pw-gray-dark) 1px solid; 	/*Darkgreen*/
	background-color: var(--pw-primary-dark); 	/*Lightgreen*/
	border-radius: .25rem;
}

.third-party-login {
	font-size: 1rem;
	line-height: 1.5rem;
	padding: 0.3rem 0.6rem;

}

/* Merda apple
a.third-party-login {
	text-align: left;
	padding-left: 25%;
}

.third-party-login i.mdi {
	position: absolute;
	left: 1.5rem;
}
*/

.daterangepicker.single .drp-calendar.left {
	padding: .5rem;
	width: 100%;
}

.daterangepicker .drp-buttons .btn.cancelBtn {
	margin: 0rem;
}

.daterangepicker.single .drp-calendar .calendar-time,
.daterangepicker.single .ranges {
	margin-top: 0rem;
}

.cr-boundary {
	max-width: 85vw;
	max-height: 85vw;
}

#profilePicOverlay {
	position: absolute;
	right: 1rem;
	display: inline-block;
	z-index: 10;
}

#profilePicOverlay .mdi {
	-webkit-filter: drop-shadow(3px 3px 3px #000);
	filter: drop-shadow(3px 3px 3px #000);
	color: var(--pw-primary)
}

.cr-upload:hover {
	color: var(--pw-primary-dark)
}

.cr-viewport {
	border: none !important;
}

.cr-slider-wrap input[type="range"].cr-slider {
	box-shadow: none !important;
	background: none !important;
}

.pswp__caption__center {
	text-align: center;
	font-size: 1rem;
}

.funnel a .mdi {
	color: white
}

.wiki .bs-tooltip-bottom {
    left: -.6rem !important;
}

.col-1.mdi-close {
	font-size: 1.5rem;
}

.col-1.mdi-close:hover {
	color: var(--pw-primary);
}

.col-1.mdi-arrow-down-bold-box-outline {
	font-size: 1.5rem;
}

.col-1.mdi-arrow-down-bold-box-outline:hover {
	color: var(--pw-primary);
}

.invalid-feedback {
	display: block !important;
}

.page-helper {
	border: 1px dashed var(--pw-primary) !important;
	font-size: 1.1rem;
	overflow: hidden;
    height: 80px;
}

.logout-social:hover {
	background-color: 1px dashed var(--pw-gray) !important;
	content: "test";
}

.logout-social span.disconnect,
.logout-social:hover span:not(.disconnect) {
	display: none;
}

.logout-social:hover span.disconnect {
	display: inline;
}

#login-facebook {
	background: #085cc4;
	background: linear-gradient(0deg, #085cc4 0%, #1398e6 100%);
	border: none;
	color: white;
}

#login-google {
	background: #d34536;
	background: linear-gradient(0deg, #d34536 0%, #eb5f50 100%);
	border: none;
	color: white;
}

#login-apple {
	background: black;
	border: 1px #787a7a solid;
	color: white;
}

.date-dropdowns select {
    height: calc(1.6em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
	width: 100%;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.date-dropdowns select:not(:last-of-type) {
	margin-right: .5rem;
}

.date-dropdowns {
    display: flex;
	justify-content: space-between;
}

/*
.cursor-pointer,
.sectionCard .img-container {
	cursor: pointer;
}
*/

.sectionCard .img-container img {
	transition: transform .2s;
	padding: .5rem;
}

.sectionCard .img-container:hover img {
	transform: scale(1.2);
}

/* APPLE */
.apple #login-home-google,
.apple #login-home-facebook,
.apple #guest-header,
.apple #guest-shopping,
.apple #guest-card,
.apple #guest-login,
.apple #guest-register,
.apple #logged-advertising,
.apple #logged-shopping,
.apple #logged-petcoin,
.apple #logged-card,
.apple #logged-feed-coming-soon,
.apple #logged-shop,
.apple #logged-marketplace,
.apple #logged-settings-account,
.apple #logged-petask-question,
.apple #row-helper-adv_list,
.apple #row-helper-card_buy,
.apple #row-helper-pet_shop,
.apple #row-helper-create_ad,
.apple #row-helper-adv_validate,
.apple #row-helper-petcoin_info {
	display: none !important;
}

.os-theme-light>.os-scrollbar>.os-scrollbar-track>.os-scrollbar-handle {
    background: var(--pw-primary);
}

.date-picker-info-text{
	text-align: right;
	padding-top: 2%;
	padding-right: 4%;
	margin-bottom: 0;
	color: var(--pw-primary);
	font-weight: 900;
	font-size: 0.65rem;
	line-height: 0.9rem;
	text-transform: uppercase;
}

.label-pet-edit-fulfillments{
	font-family: 'Poppins', sans-serif;
    font-weight: 400 !important;
	color: var(--pw-gray) !important;
	margin-bottom: 0 !important;
	padding-right: 0 !important;;
}


/*Chat*/
#conversejs .chat-head.chat-head-chatbox {
	background-color:var(--pw-primary);
}

#conversejs .chatbox .sendXMPPMessage .chat-toolbar {
	border-top: 4px solid var(--pw-primary);
}

.modal-backdrop {
	z-index: 1030!important;
	display: none;
}

#converse-modals>div>div {
	top:5rem;
}

.controlbox-heading__btn.show-client-info.fa.fa-info-circle.align-self-center {
	display: none !important;
}

/* Pulsante aggiungi amico converse.js */
.controlbox-heading__btn.add-contact.fa.fa-user-plus {
	display:none !important;
}

/*Modale Motore di Ricerca - Tablet*/

@media screen and (max-width: 1024px) and (min-width: 800px) {
	#searchModal > div {
		width: 600px;
		max-width: 600px;
		margin-top:4rem;
		margin-left: 16rem;
	}
	#hiddenField{
		top: 50%;
		right: -250% !important;
	}
}

/*Altezza Card - PetAsk*/

@media screen and (min-width: 576px) {
	div.whit-bg.shop-categories .card-body {
		min-height: 11rem;
	}
}

.zoom-in {
	animation: zoom-in 2s infinite;
	animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
}

@keyframes zoom-in {
	0%   { color: var(--pw-magenta-darker); }
	50%  { color: var(--pw-primary-dark); }
	100% { color: var(--pw-magenta-darker); }
}

/*Admin Dashboard - Grafica Aggiuntiva*/
.info-box {
	min-height: 110px;
}

.login_register_page nav.main-header h2 {
	color: var(--pw-primary-dark) !important
}

/*Landing Page - Grafica Full Page */

.landing-back-white{
	background-color:white;
}

.content-wrapper-landing{
	margin-top: 0 !important;
	margin-left: 0 !important;
}

.content-wrapper-landing .content{
	margin-top: 0 !important;
	padding:0 !important;
}

#container-landing{
	margin-left: 0 !important;
}

.content-landing{
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
	background-color:white;
}

@media screen and (max-width: 1024px) {
	.content-landing{
		max-width: 1025px;
	}
}

.landing-hero-header-text-container{
	padding: 75px 0px 75px 40px;
	text-align:center;
}

@media screen and (max-width: 763px) {
	.landing-hero-header-text-container{
		padding: 30px 20px 75px 0px;
	}
}

.landing-hero-header-text-container-regolamento{
	padding: 75px 0px 75px 40px;
	text-align:center;
}

@media screen and (max-width: 763px) {
	.landing-hero-header-text-container-regolamento{
		padding: 75px 5px 75px 5px;
	}
}

.hero-header-logo{
	margin-bottom:20px;
}

.landing-hero-header{
	background-image: url("/img/contest/landing/Petwave-Tale-Quale-Contest-Hero.jpg");
	background-size: cover;
	min-height: 80vh;
}

@media screen and (max-width: 1024px) {
	.landing-hero-header{
		min-height: 50vh;
	}
}

@media screen and (max-width: 763px) {
	.landing-hero-header{
		background-image: url("/img/contest/landing/Petwave-Tale-Quale-Contest-Hero-Mobile.jpg");
	}
}

.landing-hero-header-regolamento{
	background-image: url("/img/contest/landing/Petwave-Tale-Quale-Contest-Hero.jpg");
	background-size: cover;
	min-height: 50vh;
}

@media screen and (max-width: 1024px) {
	.landing-hero-header-regolamento{
		min-height: 20vh;
	}
}

@media screen and (max-width: 763px) {
	.landing-hero-header-regolamento{
		background-image: url("/img/contest/landing/Petwave-Tale-Quale-Contest-Hero-Mobile.jpg");
	}
}

.contenitore-immagine{
	background-color: rgba(96,96,96,0.0);
}

@media screen and (max-width: 763px) {
	.contenitore-immagine{
		background-color: rgba(96,96,96,0.5);
	}
}

.hero-header-text-01{
	color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 101px;
    font-weight: 900;
	text-shadow: 0px 0px 11px rgba(0,0,0,0.3);
	margin-top: 39px;
	margin-bottom: 20px;
	line-height: 1;
}

.hero-header-text-02{
	color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 55px;
    font-weight: 700;
	text-shadow: 0px 0px 11px rgba(0,0,0,0.3);
	margin-bottom: 20px;
	line-height: 1;
}

.hero-header-text-03{
	color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 73px;
    font-weight: 900;
	text-shadow: 0px 0px 11px rgba(0,0,0,0.3);
	margin-bottom: 0;
	line-height: 1;
	padding-top: 50px;
}

.hero-header-text-03-regolamento{
	color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 73px;
    font-weight: 900;
	text-shadow: 0px 0px 11px rgba(0,0,0,0.3);
	margin-bottom: 0;
	line-height: 1;
	padding-top: 50px;
}


@media screen and (max-width: 763px) {
	.hero-header-text-03-regolamento{
		font-size: 60px;
	}
}

.landing-row{
	background-color:white;
}

.landing-column-vertical{
	display:flex;
	justify-content:center;
	align-items:left;
	padding:1%;
}

.landing-font-green{
	color: #009378;
    font-family: 'Poppins', sans-serif;
    font-size: 37px;
    font-weight: 600;
    line-height: 1.3em;
}

.landing-font-centrale{
	color:black;
	font-family: 'Poppins', sans-serif;
    font-size: 26px;
    font-weight: 600;
	line-height: 1.6em;
	margin-bottom: 1.1em;
}

.background-landing-gradiente{
    background: linear-gradient(180deg, #E6CC01 0%, #EF9A28 100%);
}

.landing-font-hero-sec{
	color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 45px;
    font-weight: 800;
	text-shadow: 0px 0px 10px rgba(0,0,0,0.3);
	margin-bottom: 20px;
}

@media screen and (max-width: 763px) {
	.landing-font-hero-sec{
		text-align:center;
	}
}

.landing-column-yellow{
	display:flex;
	justify-content:center;
	align-items: center;
	padding:1%;
}

.landing-padding-y-35{
	padding-top: 35px;
	padding-bottom: 35px;
}

@media screen and (max-width: 1024px) {
	.landing-padding-x-15{
		padding-left: 15px;
		padding-right: 15px;
		text-align: center;
	}
}

@media screen and (max-width: 763px) {
	.landing-padding-x-15{
		padding-left: 0;
		padding-right: 0;
	}
}

.btn-landing-centrale{
	font-family: 'Poppins', sans-serif;
    font-size: 25px;
    font-weight: 800;
    fill: #009378;
    color: #009378;
	background-color: #FFFFFF;
	padding: 10px 25px;
}
.btn-landing-centrale:hover{
	color: #FFFFFF;
	background-color: #009378;
}

.landing-elenco-puntato{
	font-family: 'Poppins', sans-serif;
    font-size: 26px;
    font-weight: 600;
	line-height: 1.6em;
	color: black;
}

.margintop30{
	margin-top:30px;
}

@media screen and (max-width: 763px) {
	.margintop30{
		margin-top:15px;
	}
}

.margintop60{
	margin-top:60px;
}

@media screen and (max-width: 763px) {
	.margintop60{
		margin-top:30px;
	}
}

.margintop80{
	margin-top:80px;
}

.margintop100{
	margin-top:100px;
}

@media screen and (max-width: 763px) {
	.margintop100{
		margin-top:50px;
	}
}

.marginbottom60{
	margin-bottom:60px;
}

.btn-landing-cta{
	font-family: 'Poppins', sans-serif;
    font-size: 25px;
    font-weight: 700;
    background-color: #009378;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #009378;
	border-radius: 4px 4px 4px 4px;
	padding: 15px 50px;
}
.btn-landing-cta:hover{
	color: #009378;
	background-color: #FFFFFF;
}

.testo-regolamento{
	font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 600;
	line-height: 1.6em;
	color: #273044;
}

.regolamento-titolo{
	font-family: 'Poppins', sans-serif;
	color: #273044;
	line-height: 38px;
    font-weight: 200;
    font-size: 32px;
}

#modal-contest-style{
	background-image: url("/img/contest/landing/Petwave-Tale-Quale-Contest-Hero.jpg");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: left;
	background-color: #d2b207 !important;
}

@media screen and (max-width: 763px) {
	#modal-contest-style{
		background-position: center;
	}
}
.opacita-modal{
	background-color: rgba(0,0,0,0.5);
	border: none !important;
}


#modal-contest-style .modal-body{
	padding-left: 115px;
}

#modal-contest-style .modal-footer{
	padding-left: 102px;
}


@media screen and (max-width: 763px) {
	#modal-contest-style .modal-body{
		padding-left: 0;
	}

	#modal-contest-style .modal-footer{
		padding-left: 0;
	}
}

.modal-body.tagged-pets ul{
	list-style-type: none;
}

/*Sezione admin / Contest */
.contest-admin-post-image{
	max-height: 25px;
}

/*Home Page Carousel / Immagine Contest */
#immagine-contest-home {
	-webkit-box-shadow: 0px 0px 10px 0px #dcbf09;
	box-shadow: 0px 0px 10px 0px #dcbf09;
	}

/*Sezione Card*/
.card-buy-box-border{
	border: solid var(--pw-primary) 1px;
	border-radius: 1rem;
}

/*Nuova card Pet Protection*/
.pet-card-new{
	width:100%;
	height:auto;
	background-color:white;
	border-radius:20px;
	border-color:#90BDE6;
	border-style: solid;
	border-width: thick;
	padding:4%;
	text-align: center;
}

.logo-pet{
	width:3vw;
}

.font-header-pet{
	color:var(--pw-primary);
	margin-bottom:0;
	font-family: "petwavefont";
	letter-spacing: 0.05em;
	font-weight: 300 !important;
	font-size: 2.5vw;
	white-space: nowrap;
}

.logo-protection{
	margin-top: 2%;
}

.text-rego{
	font-family: 'Futura Std';
	color:black;
	text-transform: uppercase;
	font-size: 0.8vw;
}

.yellow-row{
	color:white;
	background-color: #F6BF6D;
	font-family: 'Futura Std';
	font-weight: 800;
	text-transform: uppercase;
	font-size: 1.1vw;
	letter-spacing: 12px;
	margin-top: 2%;
	margin-bottom: 4%;
}

.yellow-row p{
	margin-bottom:0;
}

.brown-row{
	color:white;
	background-color: #B49977;
	font-family: 'Futura Std';
	font-weight: 800;
	text-transform: uppercase;
	font-size: 1.1vw;
	letter-spacing: 2px;
}

.brown-row p{
	margin-bottom:0;

}
.celestial-background{
	background-color: #90BDE6;
}

.celestial-background p{
	margin-bottom:0;
	color:black;
	font-family: 'Futura Std';
	padding-left:6%;
	padding-right:6%;
	font-size: 0.9vw;
}

.text-big p{
	font-size: 1.1vw;
	font-weight: bold;
	padding-bottom: 0.5vw;
}

.footer-row{
	justify-content: center;
}

.footer-col{
	margin-top: 4%;
	color:white;
	background-color: #90BDE6;
	font-family: 'Futura Std';
	font-weight: 300;
	text-transform: uppercase;
	font-size: 0.4rem;
	letter-spacing: 2px;
}

.footer-col p{
	margin-bottom:0;
}

.row-footer-sponsor{
	justify-content: center;
	align-items: center;
}

//Nuove regole sezione petizioni convertita in campagne
.petition-accession-box-align{
	display: flex;
    flex-direction: column;
    justify-content: center;
	min-height: 6rem !important;
}

//Nuove per sezione acquista card vittorio manduchi
.title-card-green{
	font-size: 3rem;
	text-align: center;
	font-weight: bold;
    color: var(--pw-primary);
}

.text-card-top{
	font-size: 1.3rem;
}

.title-card-black{
	font-size: 2.4rem;
	text-align: center;
	font-weight: bold;
    color: var(--pw-black);
}

.card-green-number{
	font-size: 4.5rem;
    font-weight: 700;
	color: var(--pw-primary);
}

.title-card-black-small{
	font-size: 1.5rem;
    color: var(--pw-black);
    font-weight: 800;
}

.card-link-new, .card-link-new a{
	color: #c36 !important;
    font-weight: 700;
}

.card-button {
	background: var(--pw-primary) !important;
	border-color: var(--pw-primary) !important;
	font-family: 'Poppins', sans-serif;
	font-weight: 800 !important;
	box-shadow: none !important;
	font-size: 1.8rem !important;
	width: 100%;
	text-align: center;
	padding: .25rem !important;
}

.title-card-black-buy{
	font-size: 1.8rem;
	text-align: center;
	font-weight: 700;
    color: var(--pw-black);
}

.title-card-green-money{
	font-size: 2.4rem;
	text-align: center;
	font-weight: 800;
    color: var(--pw-primary);
}

//Classi tabelle tariffe card vittorio

.tablepress {
    border-collapse: collapse;
    border-spacing: 0;
    width: 95%;
    margin-bottom: 1em;
    border: none;
}

table thead:first-child tr:first-child th {
    border-top: 1px solid #ccc;
}

.tablepress thead th {
    border-bottom: 1px solid #ddd;
}

.tablepress tfoot th, .tablepress thead th {
    background-color: #d9edf7;
}

.tablepress td, .tablepress th {
    padding: 8px;
    border: none;
    background: 0 0;
    text-align: left;
    float: none!important;
}

.tablepress-price .column-1 {
    width: 60%;
    font-weight: 800;
}

.tablepress-price .column-2 {
    width: 20%;
    text-align: right;
}

.tablepress-price .column-3 {
    width: 20%;
    text-align: right;
    font-size: 1.2em;
    font-weight: 800;
    color: var(--pw-primary);
    padding-right: 3%;
}

@media screen and (max-width: 576px) {
	.tablepress-price .column-2 {
		text-align: center;
	}
	.tablepress-price .column-3 {
		text-align: center;
	}
	.overflow-tab{
		overflow-x: scroll; 
	}
}

.tablepress td, .tablepress th {
    padding: 15px;
    line-height: 1.5;
}

.tablepress tbody td, .tablepress tfoot th {
    border-top: 1px solid #ddd;
}

.ask_vet_link{
	text-transform: uppercase;
	text-decoration: underline;
	font-size: 1rem;
}

/* AGGIUNTE SEZIONE PUBLIC */

.post-create-tag-assistance{
	text-align: center;
	margin-bottom: 0;
	color: var(--pw-primary);
	font-weight: 700;
	font-size: 0.6rem;
	text-transform: uppercase;
}

/*BARRA INFO PETCOIN - NUOVA VERSIONE - FORMATO LIGHT - SETTEMBRE 2021 - petcoinbar_noselect.blade */
.petcoin_bar_info_text{
	color: var(--pw-primary);
	text-transform: uppercase;
    font-style: italic;
	font-weight: 900;
    font-size: 0.5rem;
	vertical-align: top;
	letter-spacing: 0.1rem;
}

/* NUOVA HOME PROFILO UTENTE MOBILE */
.rem-09 { font-size: 0.9rem; }
.rem-1 { font-size: 1rem; }
.rem-11 { font-size: 1.1rem; }
.rem-12 { font-size: 1.2rem; }

.profile-info{
color: var(--pw-primary);
font-weight: bold;
}

.line-normal{
	line-height: normal;
}

.border-top-primary{
	border-top: 1px var(--pw-primary) solid;
}

.user-name {
	font-weight: bold;
}

.tiktok .user-name {
	white-space: nowrap;
}

.tiktok{
	position:fixed;
	width: 100vw;
	height: 100vh;
	/*background-color: black;*/
	z-index: 1;
}

.privacy-switch {
	position: fixed;
	top: 50px;
	z-index: 10;
	/* background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%); */
	font-weight: 800;
}

.tiktok .dynamic-post-content{
	z-index: 2;
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.tiktok .post-image,
.tiktok .post-video{
	max-width: 100vw;
}

.tiktok .post-text{
	max-height: 60vh;
	overflow-y: scroll;
	font-size: 1.5rem;
	color: white;
}

.single-post-text{
	max-height: 60vh;
	width: 100%;
	font-size: 1rem;
	padding: .5rem;
	border-top: 1px var(--pw-primary) solid;
}

.tiktok .post-sidebar{
	z-index: 4;
	position: absolute;
	bottom: 200px;
	//top: 50%;
	//transform: translate(0,-50%);
	right: 0;
	width: max-content;
	padding: .5rem;
}

.notapple .tiktok .post-sidebar {
	bottom: 150px; //fix for iphone schermo grande
}

.tiktok .post-footer{
	z-index: 3;
	position: fixed;
	bottom: 50px;
	padding-top: 50px !important;
	color: var(--pw-white-7);
	background: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
}

.tiktok .post-footer .post-message{
	height: 1.5rem;
	//overflow-y: auto;
	margin: 0px;
	overflow: hidden;
	white-space: nowrap;
	width: 60vw;
	text-overflow: ellipsis;
}

.tiktok .petcoin-popup{
	display: none;
	z-index: 5;
	position: fixed;
	height: 300px;
	width: 98vw;
	left:1vw;
	bottom: 40px;
	background-color: var(--pw-primary-darker);
	border-radius: 1.5rem 1.5rem 0 0;
	color:whitesmoke;
}

.tiktok .comment-popup{
	display: none;
	z-index: 5;
	position: fixed;
	height: 466px; //fix Rimuovere valore fisso
	width: 98vw;
	left:1vw;
	bottom: 50px;
	background-color: var(--pw-white);
	border-radius: 1.5rem 1.5rem 0 0;
	border: 1px solid var(--pw-gray-darker);
}

.tiktok .comment-popup-header,
.tiktok .petcoin-popup-header{
	height: 30px; //fix Rimuovere valore fisso
}

.tiktok .comment-section{
	height: 320px; //fix Rimuovere valore fisso
	overflow-y: scroll;
}

.tiktok .leave-comment{
	height: 100px; //fix Rimuovere valore fisso
}

.tiktok .leave-comment textarea{
	height: 75px; //fix Rimuovere valore fisso
	max-height: 75px; //fix Rimuovere valore fisso
	overflow-y: scroll;
}

.tiktok .leave-comment button{
	position: fixed;
	right: 8px;
	bottom: 56px;
	padding: .02rem .2rem !important;
}

.tiktok .button_description,
.tiktok .mdi,
.tiktok .pwi:not(.pw-silver):not(.pw-gold){
	color: white;
	text-shadow: 0px 0px 10px #000000;
}

.comment-popup .mdi {
	color: var(--pw-gray-dark);
	text-shadow: 0px 0px 10px #bababa;
}

span.mdi.mdi-earth{
	text-shadow: none;
}

#bottom_navbar {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 50px;
    background: var(--pw-primary);
    z-index: 510;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
}

#bottom_navbar .mdi,
#top_navbar .mdi{
	/*color: var(--pw-primary-darkest);*/
	color: var(--pw-gray-post);
}


#top_navbar .pwi-petcoin-circle{
	font-size: 1.6em;
    /* top: 0.4em; */
    color: white;
}

#top_navbar {
    position: fixed;
    top: 0px;
    width: 100%;
    height: 50px;
    background: var(--pw-primary);
    z-index: 510;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
}

#top_navbar input{
	/* background: var(--pw-primary-darker);*/
	background: var(--pw-white);
	width: 100%;
	border: none;
	border-radius: 5px;
	padding: 6px;
	/*color: var(--pw-white);*/
	color: var(--pw-primary-darker);
}

#top_navbar input::placeholder {
	/* color: var(--pw-white-6); */
	color: var(--pw-primary-darker);
}

.pw-orange #top_navbar,
.pw-orange #bottom_navbar {
	background-color: var(--pw-orange);
}

.pw-yellow #top_navbar,
.pw-yellow #bottom_navbar {
	background-color: var(--pw-yellow);
}

.pw-blue #top_navbar,
.pw-blue #bottom_navbar {
	background-color: var(--pw-blue);
}

.pw-violet #top_navbar,
.pw-violet #bottom_navbar {
	background-color: var(--pw-violet);
}

.pw-green #top_navbar,
.pw-green #bottom_navbar {
	background-color: var(--pw-green);
}


#side_navbar {
    background: var(--pw-white);
    z-index: 550;
    height: 100%;
    width: 0;
    position: fixed;
    top: 0;
    right: 0;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
	//border-left: .1rem var(--pw-primary) dashed;
}

#side_navbar a,
#side_navbar .accordion,
#side_navbar .panel {
    padding: .25rem 1.5rem;
    text-decoration: none;
    font-size: 1.25rem;
    display: block;
    transition: 0.3s;
	color: var(--pw-gray-dark);
}

#side_navbar .brand-link {
	top: 1rem;
    position: absolute;
    padding: 0;
}

#side_navbar .closebtn {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 2.5rem;
}

#side_navbar .group {
	padding: 0rem 0.5rem;
}

#side_navbar .social .accordion {
	background-color: var(--pw-primary-35);
}

#side_navbar .aroundme .accordion {
	background-color: var(--pw-green-35);
}

#side_navbar .community .accordion {
	background-color: var(--pw-blue-35);
}

#side_navbar .adoptions .accordion {
	background-color: var(--pw-violet-35);
}

#side_navbar .sponsor .accordion {
	background-color: var(--pw-orange-35);
}

#side_navbar .petcoin .accordion {
	background-color: var(--pw-yellow-35);
}

#side_navbar .ecommerce .accordion {
	background-color: var(--pw-pink-35);
}

.accordion {
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
	border-radius: .5rem;
}

.panel {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.privacy_indicator {
	font-size: 1.2rem;
	color: var(--pw-white);
	font-weight: 400;
    text-shadow:
        1px 1px 0 var(--pw-black-6),
        -1px -1px 0 var(--pw-black-6),
        1px -1px 0 var(--pw-black-6),
        -1px 1px 0 var(--pw-black-6),
        1px 1px 0 var(--pw-black-6);
}

.privacy_indicator.active {
	text-decoration: underline;
	font-weight: 800 !important;
}

.pet-int-label{
    text-transform: uppercase;
    font-size: 0.6rem;
    font-weight: 700;
    color: black;
    vertical-align: middle;
}

.post-interactions.remove-action {
    position: absolute;
    top: 10px;
    right: 10px;
}

.a-column {
    position: fixed;
    right: 10px;
    top: 80px;
    width: 200px;
	overflow-y: scroll;
	max-height: 85vh;
	background-color: var(--pw-white);
	border-radius: .5rem;

	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
}

.a-column::-webkit-scrollbar {
		display: none;
	}

// Chat

.chat-container {
	max-height: 55vh;
	overflow-y: scroll;
}

.chat-bubble {
	color: var(--pw-black) !important;
}

.chat-bubble img {
	border-radius: 50%;
}

.bubble-owner {
	background-color: var(--pw-primary-light);
	border-radius: .5rem;
}

.bubble-other {
	background-color: var(--pw-gray-light);
	border-radius: .5rem;
}

.message-sent a,
.message-received a {
	font-weight: bold;
	color: var(--pw-gray-darkest);
}

.larger-modal{
	max-width: 750px !important;
	width: 100% !important;
}

.card-header.orders{
	color:#595959;
	border-radius: 10px 10px 0 0;
}

.card-header.orders a{
	font-weight: 800 !important;
	color:#156858 !important;
}


// LAST ADDED

.post-round-image-container{
	display: flex;
	justify-content: center;
}

/*
.post-round-image{
	width: 33.2px !important;
}
*/

.ratio-round {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 0;
    padding-bottom: 48%;
    position: relative;
    width: 48%;
}

.less-top-margin{
	margin-top: 1.5rem !important;
}

.helper-text {
	line-height: 1.2;
}

.menu-icon-mobile{
	width: 40px;
	height: 40px;
	filter: brightness(0.4);
}

.menu-icon-mobile-photo{
	width: 40px;
	height: 40px;
}

.text-menu-mobile{
	font-size: 1.2rem;
}
